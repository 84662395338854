import Loader from "../helpers/Loader";

const loader = new Loader();

const initialState = {
    Usuarios: [],
    Loader: loader,
    ErrorCode: null,
    Usuario: null,
    Roles: [],
    Listado: [],
    RolesUsuarios: [],
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_USUARIOS':
            return {...state, Loader: state.Loader.load()};
        case 'GOT_USUARIOS':
            return {...state, Usuarios: action.payload.usuarios, Loader: state.Loader.complete()};
        case 'GET_ROLESUSUARIOS':
            return {...state, Loader: state.Loader.load()};
        case 'GOT_ROLESUSUARIOS':
            return {...state, RolesUsuarios: action.payload.RolesUsuarios, Loader: state.Loader.complete()};
        case 'GET_LISTADO':
            return {...state, Loader: state.Loader.load()};
        case 'GOT_LISTADO':
            return {...state, Listado: action.payload.listado, Loader: state.Loader.complete()};
        case 'GET_ROLES':
            return {...state, Loader: state.Loader.load()};
        case 'GOT_ROLES':
            return {...state, Roles: action.payload, Loader: state.Loader.complete()};
        case 'GET_USUARIO':
            return {...state, Loader: state.Loader.load()};
        case 'GOT_USUARIO':
            return {...state, Usuario: action.payload.usuario, Loader: state.Loader.complete()};
        case 'REQUEST_USUARIO':
            return {...state, Loader: state.Loader.load()};
        case 'SUCCESS_USUARIO':
            return {...state, Loader: state.Loader.complete(), ErrorCode: null};
       case 'ERROR_USUARIOS':
            return {...state, ErrorCode: action.payload.status, Loader: state.Loader.complete()};
        default:
            return state;
    }
}