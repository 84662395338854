import React, {Component} from 'react';
import {Row, Col, Image} from 'react-bootstrap'
import placeholder from "../sidebar/user.svg";
import './Ficha.scss'

export default class Ficha extends Component {

    toTitleCase = (str) => {
        return str.replace(
            /\w\S*/g,
            function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }
    
    toLowerCase = (str) =>{
        if(str){
            return str.toString().toLowerCase();
        }else{
            return "-";
        }
    }

    formatName = (name, lastName) => {
        let nombre = this.toTitleCase(name.split(' ')[0]);
        let apellidos = lastName.split(' ');
        let apellidoPaterno = this.toTitleCase(apellidos[0]);
        let apellidoMaterno = apellidos[1];

        if((apellidoPaterno && apellidoPaterno.toLowerCase() === "de") || (apellidos[3] !== undefined)){
            nombre = "Hola, "+ nombre;
            apellidoPaterno = "";
            apellidoMaterno = "";
        }

        if(apellidoMaterno !== undefined && apellidoMaterno.length > 0){
            if(apellidoPaterno.toLowerCase() === "san"){
                apellidoMaterno = this.toTitleCase(apellidoMaterno);
            }else{
                apellidoMaterno = this.toTitleCase(apellidoMaterno.charAt(0))+".";
            }
        }else{
            apellidoMaterno = "";
        }

        if(nombre.length >= 10 && apellidoPaterno.length >= 10){
            apellidoPaterno = apellidoPaterno.charAt(0)+ ".";
            apellidoMaterno = "";
        }

        return nombre + " " + apellidoPaterno + " " + apellidoMaterno;
    }

    render = () => {
        return(
            <div className="p-1">
                <Row className="p-1 mx-auto">
                    <Col md={3} xs={4} className="mx-auto mt-1 d-flex justify-content-center">
                        <Image style={{height:"3.5em", border: '3px solid #cbd300'}} className="rounded-circle shadow ml-3" src={
                                    this.props.user.imagen ?
                                        "data:image/png;base64, " + this.props.user.imagen : placeholder
                                }/>
                    </Col> 
                    <Col md={9} xs={8} className="mx-auto mt-2">
                        <h5 className="text-black text-center mb-0"><b>{this.formatName(this.props.user.nombres, this.props.user.apellidos)}</b></h5>
                        <small className="text-muted text-center d-flex justify-content-center">{   this.toLowerCase(this.props.user.email)}</small>
                    </Col>
                </Row>
            </div>
        );
    }
}