import { local_token } from '../../../consts/consts'
import React, { Component } from 'react'
import { connect } from "react-redux"
import { Link, Redirect   } from "react-router-dom"
import VTable from '../../table/VTable'
import axios from 'axios'
import Axios from 'axios'
import {  Breadcrumb, Modal,  Container, Button, Form, Alert, Tooltip, OverlayTrigger } from "react-bootstrap"
import Noty from "noty"
import Workbook from 'react-excel-workbook'



class Lista_Paquetes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            files: null,
            modalSoporte: false,
            f11_srx: [],
            listado: [],
            cargando: false,
            bloquear: false,
            email: "",
            CODIGO: "",
            modalLiberar: false,
            listadosku: [{ F11: "", SKU: "", UPC: "", DESCRIPCION: "", CANTIDAD: "", COSTO_REPOSICION: "", COSTO_TOTAL: "" }],
            newlistadosku: [],
            listadoEstadosConci: [],
            listadoMotivos: [],
            ESTADO: '',
            COSTO: '',
            F11: '',
            listadosF11_Estado: [{id:"", id_con: "", F11: "",F12:"", ESTADO: "",ESTADOS:"", COSTO_BASE: "", COSTO: "", OBSERVACIONES: "", SOPORTE: "", MOTIVO_DECLINADO: "", CONCILIACION:"" }],
            MOTIVO_DECLINADO: '',
            id_con: '',
            ID_CONCILIACION: '',
            CONCILIACION:'',
            ENVIO: false,
            CONDICION:false,
        }
    }

   

    componentDidMount() {
        this.getListadoF12();
        this.getEstadosConci();
        this.getMotivos();
    };

    getListadoF12 = () => {
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        const { match: { params } } = this.props;
        var url='';
        url = "/api/v1/conciliaciones/conciliar/" + params.id + "/";
        var listadosF11_E = []
        axios.get(url, config)
            .then((response) => {
                response.data.map((elem,key) => {
                    var registro = { 
                        id:key,
                        id_con: elem.CONSECUTIVO, 
                        F11: elem.id_f11, 
                        F12: elem.F12, 
                        ESTADO: elem.ESTADO, 
                        COSTO_BASE: elem.COSTO2, 
                        COSTO: elem.VALOR, 
                        OBSERVACIONES: elem.OBSERVACIONES, 
                        SOPORTE: elem.SOPORTE, 
                        MOTIVO_DECLINADO: elem.MOTIVO ,
                        ESTADOS: elem.ESTADOS ,
                        CONCILIACION:elem.CONCILIACION
                    }
                    listadosF11_E.push(registro);
                    this.setState({CODIGO:elem.CODIGO,ID_CONCILIACION: elem.ID_CON})
                return 1});
                console.log(listadosF11_E);
                this.setState({listado: response.data, listadosF11_Estado: listadosF11_E});

            })
            .catch((error) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 500,
                    modal: true,
                    text: 'No se logró crear la conciliación'
                }).show();
            })
    };

    getEstadosConci = () => {
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        axios.get("/api/v1/conciliaciones/estadosConci/T",config)
            .then((response) => {
                this.setState({ listadoEstadosConci: response.data })
            })
            .catch((error) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 500,
                    modal: true,
                    text: 'No se lograron cargar los estados de conciliación'
                }).show();
            })
    }
    getMotivos = () => {
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        axios.get("/api/v1/conciliaciones/motivoDeclinado/",config)
            .then((response) => {
                this.setState({ listadoMotivos: response.data })
            })
            .catch((error) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 500,
                    modal: true,
                    text: 'No se lograron cargar los motivos'
                }).show();
            })
    }

    handleModalSku = f11 => () => {
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        const urls2 = "/api/v1/conciliaciones/f11/" + f11 + "/";
        var f_11 = []
        axios.get(urls2,config)
            .then((response) => {
                f_11.push(response.data);

            })
            .catch((error) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 500,
                    modal: true,
                    text: 'No se encontró el f11'
                }).show();
            });
        this.setState({ deshabilitar: false });
        var listadosku = []
        const urlbase = '/api/v1/conciliaciones/sku/'
        const url = urlbase + f11 + '/' 
        Axios.get(url,config)
            .then((response) => {
                response.data.forEach((elem) => {
                    var registro = { F11: elem.F11, SKU: elem.SKU, UPC: elem.UPC, DESCRIPCION: elem.DESCRIPCION, CANTIDAD: elem.CANTIDAD, COSTO_REPOSICION: elem.COSTO_REPOSICION, COSTO_TOTAL: elem.COSTO_TOTAL }
                    listadosku.push(registro)

                })
                this.setState({ listadosku: listadosku, f11_srx: f_11 })
            })
            .catch((error) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 500,
                    modal: true,
                    text: 'Ha ocurrido un error'
                }).show();
            })
        const valor = this.state.modalSoporte
        this.setState({ modalSoporte: !valor });
    }

    excel = () => {
        const data = this.state.listadosF11_Estado
        return (
            <Workbook filename="F11_CONCILIACIONES.xlsx" element={
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Descargar Excel</Tooltip>}>
                    <span className="d-inline-block">
                        <Button style={{ pointerEvents: 'not-allowed' }} variant="success"><i className="fas fa-download"></i></Button>&nbsp;
                    </span>
                </OverlayTrigger>}>

                <Workbook.Sheet data={data} name="listadosF11_Estado">
                    <Workbook.Column label="F11" value="F11" />
                    <Workbook.Column label="F12" value="F12" />
                    <Workbook.Column label="SUCURSAL" value="SUCURSAL" />
                    <Workbook.Column label="TRANSPORTADORA" value="TRANSPORTADORA" />
                    <Workbook.Column label="LOCALABT" value="LOCALABT" />
                    <Workbook.Column label="TIPO" value="TIPO" />
                    <Workbook.Column label="OBSERVACION" value="OBSERVACION" />
                    <Workbook.Column label="ESTADO" value="ESTADO" />
                    <Workbook.Column label="FECHA DESPACHO" value="FECHA" />
                    <Workbook.Column label="TIPIFICACION" value="TIPIFICACION" />
                </Workbook.Sheet>
            </Workbook>
        )
    }

    handleChange = ({ currentTarget: { value, name, type } }) => {

        this.setState({ [name]: value });
    };

    handleFile(e) {
        let files = e.target.files;
        this.setState({ files });
    }

    handleChangeCon = idx => evt => {
        
        const newlistadosku = this.state.listadosF11_Estado.map((listadosF11_Estado, sidx) => {           
            if (idx.F11 === listadosF11_Estado.F11){
                var motivo = evt.target.value
                var desMotivo = this.state.listadoEstadosConci.find(motivDes => motivDes.id == motivo)
                
                
                if (desMotivo.NAME === 'APROBADO VALOR DECLARADO DIFERENTE' || desMotivo.NAME === 'DECLINADO'){             
                    this.setState({modalSoporte:true, CONCILIACION : listadosF11_Estado.CONCILIACION, ESTADO:motivo});

                }
                if (motivo !== listadosF11_Estado.ESTADOS) {
                    listadosF11_Estado.ESTADOS=motivo;
                    const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
                        let config = {
                            headers: {
                                Authorization: AuthStr
                            }
                        }
                    var url_base = 'api/v1/conciliaciones/conciliar/'
                    let formData = new FormData();
                    formData.append("id", listadosF11_Estado.CONCILIACION);
                    formData.append("VALOR_CONCILIADO", listadosF11_Estado.COSTO);
                    formData.append("OBSERVACIONES", listadosF11_Estado.OBSERVACIONES);
                    formData.append("ESTADO", listadosF11_Estado.ESTADOS);
                    formData.append("SOPORTE", listadosF11_Estado.SOPORTE);
                    const urls = url_base + listadosF11_Estado.CONCILIACION + '/'
                    axios.put(urls, formData,config)
                    .then(response => {
                    }).catch(e => {
                        new Noty({
                            text: e,
                            type: "error",
                            timeout: 2000
                        }).show()
                    })
                }
                
            }
            return listadosF11_Estado
        });

        this.setState({ listadosF11_Estado: newlistadosku, F11: idx});
        
    }

    handleChangeConCosto = idx => evt => {
        
        const newlistadosku = this.state.listadosF11_Estado.map((listadosF11_Estado, sidx) => {           
            if (idx.F11 === listadosF11_Estado.F11){
                var costo = evt.target.value
                if (costo !== listadosF11_Estado.COSTO) {                    
                    listadosF11_Estado.COSTO=costo;
                    const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
                        let config = {
                            headers: {
                                Authorization: AuthStr
                            }
                        }
                    var url_base = 'api/v1/conciliaciones/conciliar/'
                    let formData = new FormData();
                    formData.append("id", listadosF11_Estado.CONCILIACION);
                    formData.append("VALOR_CONCILIADO", costo);
                    formData.append("OBSERVACIONES", listadosF11_Estado.OBSERVACIONES);
                    formData.append("ESTADO", listadosF11_Estado.ESTADOS);
                    formData.append("MOTIVO_DECLINADO", listadosF11_Estado.MOTIVO_DECLINADO);
                    formData.append("SOPORTE", listadosF11_Estado.SOPORTE);
                    const urls = url_base + listadosF11_Estado.CONCILIACION + '/'
                    axios.put(urls, formData,config)
                    .then(response => {
                    }).catch(e => {
                        new Noty({
                            text: e,
                            type: "error",
                            timeout: 2000
                        }).show()
                    })
                }
            }
            return listadosF11_Estado
        });
        this.setState({ listadosF11_Estado: newlistadosku, F11: idx});
        
    }

    handleChangeConObservacion = idx => evt => {
        
        const newlistadosku = this.state.listadosF11_Estado.map((listadosF11_Estado, sidx) => {           
            if (idx.F11 === listadosF11_Estado.F11){
                var observacion = evt.target.value
                if (observacion !== listadosF11_Estado.OBSERVACIONES) {
                    listadosF11_Estado.OBSERVACIONES=observacion;
                    const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
                        let config = {
                            headers: {
                                Authorization: AuthStr
                            }
                        }
                    var url_base = 'api/v1/conciliaciones/conciliar/'
                    let formData = new FormData();
                    formData.append("id", listadosF11_Estado.CONCILIACION);
                    formData.append("VALOR_CONCILIADO", listadosF11_Estado.COSTO);
                    formData.append("OBSERVACIONES", observacion);
                    formData.append("ESTADO", listadosF11_Estado.ESTADOS);
                    formData.append("MOTIVO_DECLINADO", listadosF11_Estado.MOTIVO_DECLINADO);
                    formData.append("SOPORTE", listadosF11_Estado.SOPORTE);
                    const urls = url_base + listadosF11_Estado.CONCILIACION + '/'
                    axios.put(urls, formData,config)
                    .then(response => {
                    }).catch(e => {
                        new Noty({
                            text: e,
                            type: "error",
                            timeout: 2000
                        }).show()
                    })
                }
            }
            return listadosF11_Estado
        });
        this.setState({ listadosF11_Estado: newlistadosku, F11: idx});
        
    }


    handleSoporteF11 = f11 => () => {
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        let files = this.state.files;
        var url_base = 'api/v1/conciliaciones/conciliar/'
        let formData = new FormData();
        formData.append("id", f11);
        formData.append("VALOR_CONCILIADO", this.state.COSTO);
        formData.append("OBSERVACIONES", this.state.OBSERVACIONES);
        formData.append("ESTADO", this.state.ESTADO);
        formData.append("MOTIVO_DECLINADO", this.state.MOTIVO_DECLINADO);
        if (files != null) {
            for (let index = 0; index < files.length; index++) {
                formData.append("SOPORTE", files[index]);
            }
        }
        else { formData.append("SOPORTE", ''); }
        const urls = url_base + f11 + '/'
        axios.put(urls, formData,config)
            .then(response => {
                new Noty({
                    text: 'Archivo cargado exitosamente',
                    type: "success",
                    timeout: 2000
                }).show()
                this.setState({COSTO:'',OBSERVACIONES:'',ESTADO:'',MOTIVO_DECLINADO:'' })
                this.getListadoF12();
            }).catch(e => {
                new Noty({
                    text: e,
                    type: "error",
                    timeout: 2000
                }).show()
            })
        const valor = this.state.modalSoporte;
        this.setState({ modalSoporte: !valor, files: null });
    }

    enviarConciliacion = id_con => () => {
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        var url_base = '/api/v1/conciliaciones/enviarConciliacion/'
        const urls = url_base + id_con + '/'
        axios.get(urls,config)
            .then(response => {
                if (response.data.Message === 200) {
                    new Noty({
                        text: 'Envio de conciliación exitosa!!',
                        type: "success",
                        timeout: 2000
                    }).show()
                    this.setState({CONDICION:true})
                }
                else {
                    new Noty({
                        text: response.data.Message,
                        type: "error",
                        timeout: 4000
                    }).show()
                }


            }).catch(e => {
                new Noty({
                    text: e.data.error,
                    type: "error",
                    timeout: 2000
                }).show()
            });
        
    }

    render() {
        const {handleChangeCon,handleChangeConCosto,handleChangeConObservacion, state: {CONDICION, listadoEstadosConci, listadoMotivos }, handleSoporteF11 } = this
        /*const options = {
            orientation: 'landscape'
        };*/
        if (CONDICION){
            return <Redirect to='/transportadora/lista_paquetes'/>;
        }
        return (
            <>
                <div>
                    <Breadcrumb>
                        <Breadcrumb.Item active><Link to="/">Inicio</Link></Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            Lista Paquetes
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Lista F11s</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Container className="p-2">
                    <Container style={{ display: "content" }}>
                        <h4><i className="fas fa-database"></i> Listado de F11s de la conciliación: {this.state.CODIGO}</h4>
                        <br />
                    </Container>

                    <br />
                    <div>
                        {this.state.listadosF11_Estado.length > 0 ?
                            <Alert variant="success">{"Se han encontrado: " + this.state.listadosF11_Estado.length + " registros"}</Alert>
                            :
                            undefined
                        }

                        
                        <VTable data={this.state.listadosF11_Estado} className="position-relative" orderBy="FECHA_REPARTO" rowsPerPage={20} orderDir="desc" sticky={false}>
                            {this.state.listadosF11_Estado.length > 0 ?
                                <nav className="form-inline justify-content-end">
                                    {(() => {
                                        if (this.state.ENVIO === false) {
                                            return (<OverlayTrigger overlay={<Tooltip id="button-tooltip">Enviar Conciliación</Tooltip>}>
                                                <span className="d-inline-block mb-2">
                                                    <Button variant="primary" onClick={this.enviarConciliacion(this.state.ID_CONCILIACION)}><i className={this.state.cargando ? "fa fa-spinner fa-spin fa-1x fa-fw" : "far fa-paper-plane"}></i></Button>&nbsp;
                                                </span>
                                            </OverlayTrigger>)
                                        }
                                    })()}
                                    <VTable.Filter className="justify-content-end form-control mb-2"
                                        placeHolder="Buscar..." />
                                </nav>
                                :
                                undefined
                            }
                            <VTable.Table className="table table-bordered table-hover table-sm text-center">
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.F11)}>F11</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.F12)}>F12</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.COSTO_BASE)}>COSTO</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={
                                    a => [
                                        <div className="text-center">
                                            <Form.Group>
                                                <Form.Control
                                                    as="select"
                                                    name='ESTADO'
                                                    value={a.ESTADOS}
                                                    disabled={this.state.ENVIO}
                                                    onChange={handleChangeCon(a)}
                                                    required >
                                                    {listadoEstadosConci.map((elem, key) => {
                                                        return <option id={elem.NAME} value={elem.id}>{elem.NAME}</option>;
                                                    })}
                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Control
                                                    as="input"
                                                    name='COSTO'
                                                    type='number'
                                                    value={a.COSTO}
                                                    disabled={this.state.ENVIO}
                                                    onChange={handleChangeConCosto(a)}
                                                    required >
                                                </Form.Control>
                                            </Form.Group>


                                            
                                        </div>
                                    ]
                                }>CONCILIAR
                                </VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={
                                    a => [
                                        <div className="text-center">
                                            <Form.Group>
                                                <Form.Control
                                                    as="input"
                                                    name='OBSERVACIONES'
                                                    value={a.OBSERVACIONES}
                                                    disabled={this.state.ENVIO}
                                                    onChange={handleChangeConObservacion(a)}
                                                    required >
                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                    ]
                                    }>OBSERVACIONES</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "1%", }} formatter={
                                    a => [
                                        <div className="text-center" key={a.ESTADOS}>
                                           

                                            {(() => {
                                                if (a.SOPORTE != "") {
                                                    return (
                                                        <a title="Ver Soporte" target="_blank" rel="noopener noreferrer" href={a.SOPORTE} className="btn-success btn-sm mt-1 p-1 mb-2 mr-1" icon="fa fa-users"><i className="fas fa-file-export" style={{ fontSize: 18 }} />Ver soporte</a>
                                                    )
                                                }
                                            })()}
                                        </div>
                                    ]
                                }>
                                    Soporte
                                </VTable.THeader>
                            </VTable.Table>
                            <nav className="d-flex justify-content-end">
                                <VTable.Pagination className="" />
                            </nav>
                        </VTable>
                    </div>
                </Container>

                <Modal show={this.state.modalSoporte} onHide={() => this.setState({ modalSoporte: false })} dialogClassName="my-modal-2" size="xl" key="modal-2">
                    <Modal.Header closeButton>
                        <Modal.Title classname="mx-4">Carga Soporte</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div >
                            <Container>
                                <Form.Group>
                                    <Form.Control
                                        as="input"
                                        type="file"
                                        onChange={(em) => this.handleFile(em)}
                                        required>
                                    </Form.Control>
                                </Form.Group>
                                <hr></hr>
                                <h5 class="mb-1">Motivo</h5>
                                <br></br>
                                <Form.Group>
                                    <Form.Control
                                        as="select"
                                        name="MOTIVO_DECLINADO"
                                        value={this.state.MOTIVO_DECLINADO}
                                        onChange={this.handleChange}
                                        required >
                                        {listadoMotivos.map((elem, key) => {
                                            return <option key={key} value={elem.id}>{elem.NAME}</option>;
                                        })}
                                    </Form.Control>
                                </Form.Group>
                                <hr></hr>
                                <h5 class="mb-1">Valor a Conciliar</h5>
                                <br></br>
                                <Form.Group>
                                    <Form.Control
                                        as="input"
                                        maxLength={50}
                                        name="COSTO"
                                        value={this.state.COSTO}
                                        disabled={this.state.bloquear}
                                        onChange={this.handleChange}
                                        required>
                                    </Form.Control>
                                </Form.Group>
                                <br></br>
                                <h5 class="mb-1">OBSERVACIONES</h5>
                                <Form.Group>
                                    <Form.Control
                                        as="input"
                                        maxLength={50}
                                        name="OBSERVACIONES"
                                        value={this.state.OBSERVACIONES}
                                        onChange={this.handleChange}
                                        required>
                                    </Form.Control>
                                </Form.Group>
                            </Container>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={(handleSoporteF11(this.state.CONCILIACION))}>Cargar Soporte</Button>
                    </Modal.Footer>
                </Modal>

            </>
        )
    }
}

const mapStateToProps = store => ({
    user: store.auth.User,
})



export default connect(mapStateToProps)(Lista_Paquetes)