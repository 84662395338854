import React, { Component } from 'react'
import { local_token } from '../../../consts/consts'
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import VTable from '../../table/VTable'
import axios from 'axios'
//import Axios from 'axios'
import moment from "moment"
import { Breadcrumb, Modal,  Container,Alert, Button, Form, Tooltip, OverlayTrigger } from "react-bootstrap"
import Noty from "noty"
import Workbook from 'react-excel-workbook';
import 'react-vertical-timeline-component/style.min.css';

//const ref = React.createRef();



class Lista_Paquetes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            listado: [],
            cargando: false,
            bloquear: false,
            email: "",
            modalLiberar: false,
            paquete: "",
            CODIGO: "",
            NUM_CON: 1,
            F4: "",
            COSTO: "",
        }
    }

    handleChange = ({ currentTarget: { value, name, type } }) => {
        /*switch (type) {
            case "text": {
                value = value
            }
        }*/
        this.setState({ [name]: value });
    };

    componentDidMount() {
        this.getListadoF12();
        const { match: { params } } = this.props;
        this.setState({ paquete: params.id })
    };

    getListadoF12 = () => {
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        const { match: { params } } = this.props;
        this.setState({ paquete: params.id })
        
        //////////////////////////////////////////
        const url2 = "/api/v1/conciliaciones/subConciliaciones/" + params.id + "/";
        axios.get(url2,config)
            .then((response) => {
                this.setState({ CODIGO: response.data.CODIGO, listado: response.data })
            })
            .catch((error) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 500,
                    modal: true,
                    text: 'No se logró listar las conciliaciones'
                }).show();
            })
    };

    

    cerrarF4 = id => () => {
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        let formData = new FormData();
        formData.append("F4", this.state.F4);
        const url = "/api/v1/conciliaciones/listaConciliaciones/" + id + "/cerrar_conciliaciones/";
        axios.post(url, formData,config)
            .then((res) => {
                new Noty({
                    type: "success",
                    text: "La conciliación se ha cerrado correctamente!!",
                    timeout: 1500
                }).show()
                this.getListadoF12();
                this.setState({F4:''})
            })
            .catch((e) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 2000,
                    modal: true,
                    text: e.response.data.Message
                }).show();
            });
        const valor = this.state.modalLiberar
        this.setState({ modalLiberar: !valor });
    };

    excel = () => {
        const data = this.state.listado
        return (
            <Workbook filename="PAQUETES_F11_CONCILIACIONES.xlsx" element={
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Descargar Excel</Tooltip>}>
                    <span className="d-inline-block">
                        <Button style={{ pointerEvents: 'not-allowed' }} variant="success"><i className="fas fa-download"></i></Button>&nbsp;
                    </span>
                </OverlayTrigger>}>

                <Workbook.Sheet data={data} name="listado">
                    <Workbook.Column label="CÓDIGO" value="F11" />
                </Workbook.Sheet>
            </Workbook>
        )
    }

    render() {
        const {  state: { CODIGO }, } = this
        /*const options = {
            orientation: 'landscape'
        };*/
        return (
            <>
                <div>
                    <Breadcrumb>
                        <Breadcrumb.Item active><Link to="/">Inicio</Link></Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            Lista Paquetes
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Lista Sub Conciliaciones</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Container className="p-2">
                    <Container style={{ display: "content" }}>
                        <h4><i className="fas fa-database"></i> Listado de Sub Conciliaciones</h4>
                        <hr />
                    </Container>
                    <div>
                        {this.state.listado.length > 0 ?
                            <Alert variant="success">{"Se han encontrado: " + this.state.listado.length + " registros"}</Alert>
                            :
                            undefined
                        }
                        <VTable data={this.state.listado} className="position-relative" orderBy="FECHA_REPARTO" rowsPerPage={20} orderDir="desc" sticky={false}>
                            {this.state.listado.length > 0 ?
                                <nav className="form-inline justify-content-end">
                                    <VTable.Filter className="justify-content-end form-control mb-2"
                                        placeHolder="Buscar..." />
                                </nav>
                                :
                                undefined
                            }
                            <VTable.Table className="table table-bordered table-hover table-sm text-center">
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.CODIGO)}>CODIGO</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.VALOR_CONCILIADO)}>VALOR</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "1%", }} formatter={
                                    a => [
                                        <div className="text-center" key="1">

                                            

                                            {(() => {
                                                if (a.ENVIO === true && a.F4 > 0) {
                                                    return (
                                                        <span class="badge badge-success mr-1">Cerrado-{a.F4}</span>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <Button title="Cerrar" variant="warning" className="btn-sm mt-1 p-1 mb-2 mr-1" icon="fa fa-users" onClick={() => this.setState({ modalLiberar: true, datos: a })} >Cerrar </Button>
                                                    )
                                                }
                                            })()}




                                        </div>
                                    ]
                                }>
                                    ACCIONES
                                </VTable.THeader>
                            </VTable.Table>
                            <nav className="d-flex justify-content-end">
                                <VTable.Pagination className="" />
                            </nav>
                        </VTable>
                    </div>
                </Container>
                {
                    this.state.datos &&
                    <Modal show={this.state.modalLiberar} onHide={() => this.setState({ modalLiberar: false })} dialogClassName="modal-correo" overflow="auto">
                        <Modal.Header closeButton>
                            <Modal.Title classname=" text-center mx-4">¿Está seguro de cerrar la conciliación: {CODIGO}-{this.state.datos.CODIGO}?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h5 class="mb-1">Digite F4:</h5>
                            <Form.Group>
                                <Form.Control
                                    as="input"
                                    maxLength={50}
                                    name="F4"
                                    value={this.state.F4}
                                    onChange={this.handleChange}
                                    required>
                                </Form.Control>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button class="text-center" variant="success" onClick={(this.cerrarF4(this.state.datos.COD_CON))}>Enviar Cierre</Button>
                            <Button onClick={() => this.setState({ modalLiberar: false, F4: '', COSTO: '' })}>Cancelar</Button>
                        </Modal.Footer>
                    </Modal>
                }
            </>
        )
    }
}

const mapStateToProps = store => ({
    user: store.auth.User,

})

export default connect(mapStateToProps)(Lista_Paquetes)