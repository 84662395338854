import { combineReducers } from 'redux';
import auth from './authReducer';
import menu from './menuReducer';
import usuario from './usuarioReducer';

const rootReducer = combineReducers({
    auth,
    menu,
    usuario,
});

export default rootReducer