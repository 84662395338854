import React, { Component } from 'react'
import { connect } from "react-redux"
import { userLogin } from "../actions/authActions"
import qs from 'query-string'
import axios from 'axios'
import Inicio from './Inicio'
import Loading from "./Loading"
//import { Redirect } from "react-router-dom"
import { local_token } from "../consts/consts"
import Noty from "noty"
//import NoAuth from './NoAuth';
import Logo from './Logo';
import Login from "./Signup"

export class Auth extends Component {

    componentDidMount = () => {
        let qu = qs.parse(this.props.location.search)
        axios.interceptors.request.use(
            config => {
                if (!config.headers.Authorization && localStorage[local_token]) {
                    config.headers.Authorization = `Token ${localStorage[local_token]}`
                }
                return config
            },
            error => {
                console.dir(error)
                return Promise.reject(error)
            }
        )
        axios.interceptors.response.use(
            config => config,
            error => {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            new Noty({
                                type: "error",
                                text: "No está autorizado",
                            }).show()
                            break;
                        default: break;
                    }
                }

                return Promise.reject(error)
            }
        )
        if (qu.token) {
            this.props.userLogin({ token: qu.token })
        }
        if (localStorage[local_token]) {
            this.props.userLogin();
        }
    }

    render = () => {
        const { loading } = this.props;
        //let qu = qs.parse(this.props.location.search)
        if (this.props.loading)
            return (
                <Logo />
            )
        if (this.props.auth) {
            return (
                <Inicio />
            )
        }
        return (
            <div className="">
                {
                    loading &&
                    <Loading className="position-absolute w-100 h-100"
                        style={{ backgroundColor: "#ffffff99", zIndex: 9999 }} />
                }
                {!this.props.auth && <Login redireccionar={this.redireccionar} />}
            </div>
        )
    }
    redireccionar = () => {
        //let reportes = 1;
        //let sistemas = 1;
        this.props.history.push("/")
    }

}



const mapStateToProps = store => ({
    auth: !!store.auth.User,
    loading: store.auth.Loader.isLoading,
    errorAuth: store.auth.ErrorCode
})

const mapDispatchToProps = dispatch => ({
    userLogin: (obj) => dispatch(userLogin(obj))
})

export default connect(mapStateToProps, mapDispatchToProps)(Auth)