import React, { Component } from 'react'
import { local_token } from '../../../consts/consts'
import WorkIcon from "@material-ui/icons/AirportShuttle";
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import axios from 'axios'
import moment from "moment"
import { Breadcrumb, Modal, Container,Button, Tooltip, OverlayTrigger } from "react-bootstrap"
import Noty from "noty"
import Workbook from 'react-excel-workbook';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';


class Lista_Paquetes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            listado: [],
            cargando: false,
            bloquear: false,
            email: "",
            modalLiberar: false,
            paquete: "",
            CODIGO: "",
            NUM_CON: 1,
        }
    }

    handleChange = ({ currentTarget: { value, name, type } }) => {
        /*switch (type) {
            case "text": {
                value = value
            }
        }*/
        this.setState({ [name]: value });
        this.getListadoF12();
    };

    componentDidMount() {
        this.getListadoF12();
        const { match: { params } } = this.props;
        this.setState({ paquete: params.id })
    };

    getListadoF12 = () => {
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        const { match: { params } } = this.props;
        this.setState({ paquete: params.id })
        
        const url2 = "/api/v1/conciliaciones/listaConciliaciones/" + params.id + "/lista_conciliaciones/";
        axios.get(url2, config)
            .then((response) => {   
                this.setState({ CODIGO: response.data, listado: response.data })
            })
            .catch((error) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 500,
                    modal: true,
                    text: 'No se logró listar las conciliaciones'
                }).show();
            })
    };

    liberarF11 = id => () => {
        const url = "/f11/enviar/" + id + "/";
        axios.post(url)
            .then((res) => {
                new Noty({
                    type: "success",
                    text: "Paquete de F11s liberado correctamente",
                    timeout: 1500
                }).show()
            })
            .catch((e) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 2000,
                    modal: true,
                    text: e.response.data.Message
                }).show();
            });
        const valor = this.state.modalLiberar
        this.setState({ modalLiberar: !valor });
    };

    excel = () => {
        const data = this.state.listado
        return (
            <Workbook filename="PAQUETES_F11_CONCILIACIONES.xlsx" element={
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Descargar Excel</Tooltip>}>
                    <span className="d-inline-block">
                        <Button style={{ pointerEvents: 'not-allowed' }} variant="success"><i className="fas fa-download"></i></Button>&nbsp;
                    </span>
                </OverlayTrigger>}>

                <Workbook.Sheet data={data} name="listado">
                    <Workbook.Column label="CÓDIGO" value="F11" />
                </Workbook.Sheet>
            </Workbook>
        )
    }

    render() {
        return (
            <>
                <div>
                    <Breadcrumb>
                        <Breadcrumb.Item active><Link to="/">Inicio</Link></Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            Lista Paquetes
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Lista Conciliaciones</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Container className="p-2" style={ { background: 'rgb(189, 189, 189,.13)'}}>
                    <Container style={{ display: "content" }}>
                        <h4><i className="fas fa-database"></i> Listado de Conciliaciones
                        
                        
                        </h4>
                        
                        
                        <hr />
                    </Container>
                    <VerticalTimeline>
                    {
                        this.state.listado.map((elem, idx) => (
                            
                            <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ boxShadow: '0 .5rem 1rem rgba(0,0,0,.40)', border:'1px solid rgba(0, 0, 0, 0.20)', color: 'rgb(0, 0, 0)', borderRadius: '15px' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date=""
                            iconStyle={{ background: 'rgb(255, 255, 255)', color: 'rgb(148, 148, 148)' }}
                            icon={<WorkIcon />}
                        >
                            <h3 className="vertical-timeline-element-title">{moment(elem.FECHA.substr(0,10)).format("MMMM D, YYYY")}</h3>
                            <br />
                            <h4 className="vertical-timeline-element-subtitle">{elem.CODIGO  }</h4>
                            <br />
                        { idx % 2 === 0 ?
                        <Container>
                        <Container style={ { background: 'rgb(33, 150, 243)', color: '#fff', padding:'15px',borderRadius: '15px' } }>
                            <h5>Valor Esperado</h5>
                            <h6>{elem.VALOR_ESPERADO}</h6>
                            <hr />
                            <h5>Valor Conciliado</h5>
                            <h6>{elem.VALOR_CONCILIADO}</h6>
                            <hr />
                            <h5>Valor Declarado Total</h5>
                            <h6>{elem.VALOR_DECLARADO}</h6>
                            <hr />
                            <h5>Valor Declarado Parcial</h5>
                            <h6>{elem.VALOR_PARCIAL}</h6>
                            <hr />
                            <h5>Valor Declinado</h5>
                            <h6>{elem.VALOR_DECLINADO}</h6>
                        </Container>
                        {
                                this.state.listado.length-1 === idx  && ((elem.DECLINADO > 0 || elem.VALOR_PARCIAL !== 0) && elem.ENVIO ==true) ?
                                <Link title="Iniciar Conciliaciones" to={`/falabella/ConciliacionFalabella/${this.state.paquete}`}><Button title="Iniciar Conciliacion" variant="success" className="btn-sm mt-1 p-1 mb-2 mr-1" ><i className="fas fa-cogs" style={{ fontSize: 18 }} />   Iniciar Conciliacion</Button></Link>
                                :
                                undefined   
                                }
                        </Container>
                            :
                            <Container style={ { background: 'rgb(191, 213, 49)', color: '#fff', padding:'15px',borderRadius: '15px' } }>
                                <h5>Valor Aprobado Falabella</h5>
                                <h6>{elem.VALOR_APROBADO}</h6>
                                <hr />
                                <h5>Valor Denegado Falabella</h5>
                                <h6>{elem.VALOR_DENEGADO}</h6>
                               
                            </Container>

                        }
                             {
                                this.state.listado.length-1 === idx && idx % 2 !== 0 && elem.ENVIO ===false  ?
                                <Link title="Continuar Conciliacion" to={`/falabella/ConciliacionFalabella/${this.state.paquete}`}><Button title="Continuar Conciliacion" variant="success" className="btn-sm mt-1 p-1 mb-2 mr-1" ><i className="fas fa-cogs" style={{ fontSize: 18 }} />   Continuar Conciliacion</Button></Link>
                                :
                                undefined   
                                } 
                            
                            
                        </VerticalTimelineElement>
                        ))
                    }

                        
                        
                    </VerticalTimeline>

                    



                </Container>
                {
                    this.state.datos &&
                    <Modal show={this.state.modalLiberar} onHide={() => this.setState({ modalLiberar: false })} dialogClassName="modal-correo" overflow="auto">
                        <Modal.Header closeButton>
                            <Modal.Title classname=" text-center mx-4">¿Está seguro de enviar el Paquete: {this.state.datos.CODIGO}-{this.state.datos.TRANSPORTADORA}?</Modal.Title>
                        </Modal.Header>
                        <Modal.Footer>
                            <Button class="text-center" variant="success" onClick={(this.liberarF11(this.state.datos.id))}>Enviar</Button>
                            <Button onClick={() => this.setState({ modalLiberar: false })}>Cerrar</Button>
                        </Modal.Footer>
                    </Modal>
                }
            </>
        )
    }
}

const mapStateToProps = store => ({
    user: store.auth.User,

})

export default connect(mapStateToProps)(Lista_Paquetes)