import React, { Component } from "react"
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { Route, Switch} from "react-router-dom";
import Lista_Paquetes from "./Lista_Paquetes";
import Lista_F11_Paquete from "./Lista_F11_Paquete";
import Lista_Conciliciaciones from "./Lista_Conciliciaciones";
import ConciliacionTransportadora from "./ConciliacionTransportadora"

export class Contenedor extends Component {
    render = () => (
        <div className="h-100">
            <Container fluid className="px-5 py-1">
                <Switch>
                    <Route path="/transportadora/lista_paquetes" component={Lista_Paquetes} />
                    <Route path="/transportadora/lista_f11_paquete/:id"  component={Lista_F11_Paquete}  />
                    <Route path="/transportadora/lista_conciliaciones/:id"  component={Lista_Conciliciaciones}  />
                    <Route path="/transportadora/ConciliacionTransportadora/:id"  component={ConciliacionTransportadora}  />
                </Switch>
            </Container>
        </div>
    );
}

const mapStateToProps = store => ({
    // loading: store.contenedor.Loader.isLoading,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Contenedor);