import { local_token } from '../../../../consts/consts'
import React, { Component } from 'react';
import { Alert, Button, Col, Form, Card, Container, InputGroup, Image, Popover, OverlayTrigger } from "react-bootstrap";
import Noty from 'noty';
import axios from 'axios';
import placeholder from "../../../sidebar/user.svg";


class Formulario extends Component {

    static defaultProps = {
        request: {
            RUT: '',
            email: '',
            nombres: '',
            apellidos: '',
            FOTO: '',
            CONTINUE: true,
            BLOQUEAR: true,
            BLOQUEAR2: true,
            cargando: false,
            botoncontinuar: true,
            error: '',
        },
    }

    constructor(props) {
        super(props)
        this.state = {
            ...props.request
        }
    }

    handleChange = ({ currentTarget: { value, name } }) => {
        if (value.toUpperCase().match("^[A-Za-z-@ ]*$") !== null) {            
            this.setState({ [name]: value.toUpperCase() })
        }
    }

    handleChangem = ({ currentTarget: { value, name } }) => {
        if (value.toUpperCase() !== null) {            
            this.setState({ [name]: value.toUpperCase() })
        }
    }

    handleChangeInt = ({ currentTarget: { value } }) => {
        if (value.toUpperCase().match("^[0-9-K]*$") !== null) {
            if (this.state.RUT !== value) {
                this.clear()
            }
            this.setState({ RUT: value })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.props.onSubmit({ ...this.state }, e)
    }

    verificarRut = () => {
        this.setState({ cargando: true, error: "" })
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        if(this.state.RUT.match("^[0-9]*$")== null){   
            this.setState({error: "Formato de RUT inválido", cargando: false})
        }else{
            axios.get("/api/v1/usuario/usuario/" + this.state.RUT,config)
            .then((res) => {
                if (res.data !== undefined) {
                    this.setState({ nombres: res.data.nombres, apellidos: res.data.apellidos, email: res.data.email, cargando: false, BLOQUEAR: true, FOTO: res.data.FOTO, botoncontinuar: false })
                }
                new Noty({
                    type: "success",
                    text: "Usuario encontrado",
                    timeout: 1500
                }).show()
            }).catch((e) => {
                if (e.response && e.response.data && e.response.data.Message) {
                    if (e.response.data.Message === "Usuario ya existe en la nómina actual") {
                        this.setState({ nombres: '', apellidos: '', email: '', cargando: false, BLOQUEAR: true })
                        new Noty({
                            type: "error",
                            text: e.response.data.Message,
                            timeout: 2000
                        }).show()
                    } else {
                        this.setState({ nombres: '', apellidos: '', email: '', cargando: false, BLOQUEAR: false, BLOQUEAR2: false, botoncontinuar: false })
                        new Noty({
                            type: "error",
                            text: e.response.data.Message,
                            timeout: 2000
                        }).show()
                    }
                }
            })
        }
    }

    clear = () => {
        this.setState({ RUT: '', nombres: '', apellidos: '', email: '', ALMACEN: '', AREA: '', CONTINUE: true, BLOQUEAR: true, BLOQUEAR2: true, FOTO: '', botoncontinuar: true, error:'' })
    }

    blockClose=()=>{
        let aux = false
        const usr = this.props.rolesUsuarios.find(x=>x.RUT === this.state.RUT)

        if(usr !== null){
            aux = false;
        }else{
            aux= true;
        }
        return aux
    }


    render() {
        const { handleChange,handleChangem, handleChangeInt, handleSubmit, state: { RUT, CONTINUE, nombres, apellidos, email, FOTO, cargando, BLOQUEAR, botoncontinuar }, props: { alert } } = this
        const bloquear = this.blockClose();
        const popover = (
            <Popover id="popover-basic">
                <Popover.Title as="h3">Ejemplo</Popover.Title>
                <Popover.Content>
                            <div>
                        <table className="table-bordered">
                            <tbody>
                                <tr>
                                    <th>11111111-0</th>
                                    <td><i className="fas fa-times" style={{ color: "red", fontSize: 17 }}></i></td>
                                    
                                </tr>
                                <tr>
                                    <th>11111111</th>
                                    <td><i className="fas fa-check" style={{ color: "green", fontSize: 14 }}></i></td>
                                </tr>
                            </tbody>
                        </table>
                            </div>
                </Popover.Content>
            </Popover>
        );
        return (
            <Form onSubmit={handleSubmit} autoComplete="off">
                {
                    alert &&
                    <Alert variant={alert.type}>
                        {
                            alert.message
                        }
                    </Alert>
                }

                <Container>
                    {
                        this.props.request.RUT === "" ?
                            <>
                                <Form.Row>
                                    <Form.Group md={8} as={Col}>
                                        <Card>
                                            <Card.Header>Datos usuario</Card.Header>
                                            <Card.Body>
                                                <Form.Row>
                                                    <Form.Group md={8} as={Col} className="mt-4">
                                                        <Form.Label className="mx-2">Rut (sin puntos ni dígito verificador)</Form.Label>
                                                        <OverlayTrigger trigger="hover" placement="bottom" overlay={popover}>
                                                            <i className="fas fa-question-circle" style={{fontSize: 17, color: "green"}}></i>
                                                        </OverlayTrigger>
                                                        <InputGroup>
                                                            <Form.Control
                                                                type="text"
                                                                name="RUT"
                                                                value={RUT}
                                                                placeholder={"12345678"}
                                                                onChange={handleChangeInt}
                                                                disabled={CONTINUE !== true}
                                                                maxLength="10"
                                                                required
                                                                isInvalid={this.state.error !== ""}>
                                                            </Form.Control>
                                                            <InputGroup.Append>
                                                                {
                                                                    nombres !== '' || apellidos !== '' || email !== '' ?
                                                                        <Button variant="warning" disabled={CONTINUE === false} onClick={() => this.clear()}>Limpiar</Button>
                                                                        :
                                                                        <Button variant={cargando ? "outline-secondary" : "secondary"} disabled={RUT === '' || cargando} onClick={() => this.verificarRut(RUT)}><i className={cargando ? "fa fa-spinner fa-spin fa-1x fa-fw" : undefined}></i>{cargando ? "   Verificando" : "Verificar"}</Button>
                                                                }
                                                            </InputGroup.Append>
                                                            <Form.Control.Feedback type="invalid">{this.state.error}</Form.Control.Feedback>
                                                        </InputGroup>     
                                                    </Form.Group>
                                                    <Form.Group md={4} as={Col} className="text-center">
                                                    </Form.Group>
                                                </Form.Row>

                                                <Form.Row>
                                                    <Form.Group as={Col}>
                                                        <Form.Label>Nombres</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="nombres"
                                                            onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                                                            value={nombres}
                                                            maxLength="30"
                                                            disabled={BLOQUEAR}
                                                            onChange={handleChange}
                                                            required>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group as={Col}>
                                                        <Form.Label>Apellidos</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="apellidos"
                                                            maxLength="30"
                                                            pattern="[a-zA-Z ]{2,254}"
                                                            value={apellidos}
                                                            disabled={BLOQUEAR}
                                                            onChange={handleChange}
                                                            required>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col}>
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            name="email"
                                                            maxLength="40"
                                                            value={email}
                                                            pattern="[a-zA-Z0-9!#$%&'*_+-]([\.]?[a-zA-Z0-9!#$%&'*_+-])+@[a-zA-Z0-9]([^@&%$\/()=?¿!.,:;]|\d)+[a-zA-Z0-9][\.][a-zA-Z]{2,4}([\.][a-zA-Z]{2})?"
                                                            disabled={BLOQUEAR}
                                                            onChange={handleChangem}>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Form.Row>   
                                            </Card.Body>
                                        </Card>
                                    </Form.Group>
                                    <Form.Group md={4} as={Col}>
                                        <Card>
                                            <Card.Header>
                                                Roles usuario
                                        </Card.Header>
                                            <Card.Body>
                                                <h5>Roles: is</h5>
                                                {
                                                    this.props.roles.map(rol =>
                                                        <Form.Row className="text-center" key={rol.id}>
                                                            <label>
                                                                <input type="checkbox"
                                                                    required
                                                                    key={rol.id}
                                                                    value={rol.id}
                                                                    disabled={CONTINUE || this.props.loadingUsuario}
                                                                    checked={this.props.rolesUsuarios.find(a => a.RUT == RUT && a.groups === rol.id) ? true : false}
                                                                    onChange={() => this.props.seleccionRoles(RUT, rol.id)}
                                                                />
                                                                {" " + rol.name}
                                                            </label>
                                                        </Form.Row>)

                                                }
                                            </Card.Body>
                                        </Card>
                                    </Form.Group>
                                </Form.Row>
                                <div className="text-right">
                                    {
                                        CONTINUE === false ?

                                            <Button variant="secondary" disabled={bloquear} onClick={() => this.props.cerrarModal(false)}>Cerrar</Button>
                                            :
                                            <>
                                            <Button type="submit" disabled={botoncontinuar || BLOQUEAR} className="mx-1">Continuar</Button>
                                            <Button variant="secondary" onClick={() => this.props.cerrarModal(false)}>Cerrar</Button>
                                            </>
                                    }
                                </div>
                            </>
                            : this.props.request !== undefined ?
                                <>
                                    <Form.Row>
                                        <Form.Group md={8} as={Col}>
                                            <Card>
                                                <Card.Header>Datos usuario</Card.Header>
                                                <Card.Body>
                                                    <Form.Row>
                                                        <Form.Group md="8" as={Col} className="mt-4">
                                                            <Form.Label className="mx-2">Rut (sin puntos ni dígito verificador)</Form.Label>
                                                            <OverlayTrigger trigger="hover" placement="bottom" overlay={popover}>
                                                                <i className="fas fa-question-circle" style={{ fontSize: 17, color: "green" }}></i>
                                                            </OverlayTrigger>                                                            
                                                            <Form.Control
                                                                type="text"
                                                                name="RUT"
                                                                value={RUT}
                                                                disabled
                                                                onChange={handleChangeInt}
                                                                maxLength="8"
                                                                required>
                                                            </Form.Control>

                                                        </Form.Group>
                                                        <Form.Group md="4" as={Col} className="text-center">
                                                        </Form.Group>

                                                    </Form.Row>

                                                    <Form.Row>
                                                        <Form.Group as={Col}>
                                                            <Form.Label>Nombres</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="nombres"
                                                                value={nombres}
                                                                onChange={handleChange}>
                                                            </Form.Control>
                                                        </Form.Group>

                                                        <Form.Group as={Col}>
                                                            <Form.Label>Apellidos</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="apellidos"
                                                                value={apellidos}
                                                                pattern="[a-zA-Z ]{2,254}"
                                                                onChange={handleChange}>
                                                            </Form.Control>

                                                        </Form.Group>
                                                    </Form.Row>

                                                    <Form.Row>
                                                        <Form.Group as={Col}>
                                                            <Form.Label>Email</Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                name="email"
                                                                value={email}                                                                
                                                                pattern="[a-zA-Z0-9!#$%&'*_+-]([\.]?[a-zA-Z0-9!#$%&'*_+-])+@[a-zA-Z0-9]([^@&%$\/()=?¿!.,:;]|\d)+[a-zA-Z0-9][\.][a-zA-Z]{2,4}([\.][a-zA-Z]{2})?"
                                                                onChange={handleChangem}>
                                                            </Form.Control>

                                                        </Form.Group>
                                                    </Form.Row>
                                                </Card.Body>
                                            </Card>

                                        </Form.Group>
                                        <Form.Group md={4} as={Col}>
                                            <Card>
                                                <Card.Header>Roles</Card.Header>
                                                <Card.Body>
                                                    {
                                                        this.props.roles.map(rol =>
                                                            <Form.Row className="text-center" key={rol.id}>
                                                                <label>
                                                                    <input type="checkbox"
                                                                        key={rol.id}
                                                                        value={rol.id}
                                                                        disabled={CONTINUE || this.props.loadingUsuario}
                                                                        checked={this.props.rolesUsuarios.find(a => a.RUT === this.props.request.RUT && a.groups === rol.id) ? true : false}
                                                                        onChange={() => this.props.seleccionRoles(this.props.request.RUT, rol.id)}
                                                                    />
                                                                    {" " + rol.name}
                                                                </label>
                                                            </Form.Row>)
                                                    }
                                                </Card.Body>
                                            </Card>
                                        </Form.Group>
                                    </Form.Row>
                                    <div className="text-right">
                                        <Button type="submit" className="mx-1">Guardar</Button>
                                        <Button variant="secondary" onClick={() => this.props.cerrarModal(false)}>Cerrar</Button>
                                    </div>
                                </>
                                : undefined}

                </Container>
            </Form>
        )
    }
}

export default (Formulario)