import React, {Component} from 'react';
import Container from "react-bootstrap/Container";

class Bienvenida extends Component {
    render() {
        return (
            <Container className="py-2">
                <h5>
                    <strong>
                    {"Bienvenido a Plataforma de "+ process.env.REACT_APP_NOMBRE}
                    </strong>
                </h5>
            </Container>
        );
    }
}
export default Bienvenida;