import React, { Component } from "react"
//import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import ContenidoUsuario from "./Usuarios/Tabla";

export class Contenedor extends Component {
    render = () => (
                <Switch>
                    <Route path="/administrador/usuarios" component={ContenidoUsuario} />
                </Switch>
    );
}

const mapStateToProps = store => ({
    // loading: store.contenedor.Loader.isLoading,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Contenedor);