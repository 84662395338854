import { local_token, local_refresh } from '../consts/consts'
import axios from 'axios';
import Noty from 'noty'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";


export default this;

export const fetchUsuariosAction = () => {
    return dispatch => {
        dispatch(getUsuarios({}));
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        return axios.get(
            `api/v1/usuario/usuario/`, config
        ).then(d => {
            let data = d.data;
            dispatch(gotUsuarios({ usuarios: data }));
        }).catch(error => {
            dispatch(errorUsuarios({ status: error }));
        });
    }
};
export const fetchUsuariosInactivosAction = () => {
    return dispatch => {
        dispatch(getUsuarios({}));
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        return axios.get(
            `api/v1/usuario/usuario/`,config
        ).then(d => {
            let data = d.data;
            dispatch(gotUsuarios({ usuarios: data }));
        }).catch(error => {
            dispatch(errorUsuarios({ status: error }));
        });
    }
};


export const fetchRefreshAction = () => {
    return dispatch => {
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
            let formData = new FormData();
            formData.append("user", localStorage[local_refresh]);
        return axios.post(`api/v1/usuario/refresh/`,formData,config
        ).then(d => {
            let data = d.data;
            localStorage.setItem(local_token, data.token);
            localStorage.setItem(local_refresh, data.user.id);
        }).catch(error => {
            localStorage.removeItem(local_token);
            localStorage.removeItem(local_refresh);
        });
    }
};

export const listadoUsuariosAction = () => {
    return dispatch => {
        dispatch(getListado({}));
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        return axios.get(
            `api/v1/usuario/usuario/`,config
        ).then(d => {
            let data = d.data;
            dispatch(gotListado({ listado: data }));
        }).catch(error => {
            dispatch(errorUsuarios({ status: error }));
        });
    }
};

export const listadoUsuariosInactivosAction = () => {
    return dispatch => {
        dispatch(getListado({}));
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        return axios.get(
            `api/v1/usuario/usuario/`,config
        ).then(d => {
            let data = d.data;
            dispatch(gotListado({ listado: data }));
        }).catch(error => {
            dispatch(errorUsuarios({ status: error }));
        });
    }
};

export const RolesUsuariosAction = () => {
    return dispatch => {
        dispatch(getRolesUsuarios({}));
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        return axios.get(
            `api/v1/usuario/usuario/list_usuario_g/`,config
        ).then(d => {
            let data = d.data;
            dispatch(gotRolesUsuarios({ RolesUsuarios: data }));
        }).catch(error => {
            dispatch(errorUsuarios({ status: error }));
        });
    }
};

export const fetchUsuarioAction = (usuarioId) => {
    return dispatch => {
        dispatch(getUsuario({}));
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        return axios.get(
            `api/v1/usuarios/usuarios/${usuarioId}`,config
        ).then(d => {
            let data = d.data;
            dispatch(gotUsuario({ usuario: data }));
        }).catch(error => {
            dispatch(errorUsuarios({ status: error }));
        });
    }
};

export const createUsuarioAction = (usuario) => {
    return dispatch => {
        dispatch(requestUsuario);
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        
        let formData = new FormData();
        formData.append("username", usuario.RUT);
        formData.append("RUT", usuario.RUT);
        formData.append("email", usuario.email);
        formData.append("nombres", usuario.nombres);
        formData.append("apellidos", usuario.apellidos);
        formData.append("password", Math.random().toString(36).substring(0,10));
        return axios.post(
            `api/v1/usuario/usuario/`,formData,
            config
        ).then(() => {
            dispatch(successUsuario);
        }).catch(error => {
            new Noty({
                type: "error",
                text: error.response.data.message,
                timeout: 2000,
            }).show();
            dispatch(errorUsuarios({ status: error.response }));
        });
    }
};

export const updateUsuarioAction = (usuario) => {
    return dispatch => {
        dispatch(requestUsuario);
        let formData = new FormData();
        formData.append("username", usuario.RUT);
        formData.append("RUT", usuario.RUT);
        formData.append("email", usuario.email);
        formData.append("nombres", usuario.nombres);
        formData.append("apellidos", usuario.apellidos);
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        
        return axios.put(
            `api/v1/usuario/usuario/${usuario.id}/`,formData,
            config
        ).then(d => {
            console.log(usuario);
            dispatch(successUsuario);
        }).catch((error) => {
            new Noty({
                type: "error",
                text: error.response.data.message,
                timeout: 2000,
            }).show();
            dispatch(errorUsuarios({ status : error.response }));
        });
    }
};

export const deleteUsuarioAction = (usuarioId) => {
    return dispatch => {
        dispatch(requestUsuario);
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        return axios.delete(
            `api/v1/usuario/usuario/${usuarioId}`,config
        ).then(() => {
            dispatch(successUsuario);
        }).catch(error => {
            dispatch(errorUsuarios({ status: error }));
        });
    }
};

export const activarUsuarioAction = (usuarioId) => {
    return dispatch => {
        dispatch(requestUsuario);
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        return axios.post(
            `api/v1/usuario/usuario/${usuarioId}/activar_usuario/`,'',config
        ).then(() => {
            dispatch(successUsuario);
        }).catch(error => {
            dispatch(errorUsuarios({ status: error }));
        });
    }
};

export const createRolAction = (usuarioId, rol) => {
    console.log('ingresa')
    return dispatch => {
        dispatch(requestUsuario);
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        let data = {'rol':rol}
        console.log('ingresa')
        return axios.put(
            `api/v1/usuario/usuario/${usuarioId}/agregar_grupo/`,data,config
        ).then(d => {
            dispatch(successUsuario);
        }).catch(error => {
            dispatch(errorUsuarios({ status: error }));
            //localStorage.removeItem(local_token);
            //localStorage.removeItem(local_refresh);
        });
    }
};

export const deleteRolAction = (usuarioId, rol) => {
    return dispatch => {
        dispatch(requestUsuario);
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
              let data = {'rol':rol}      
        return axios.put(
            `api/v1/usuario/usuario/${usuarioId}/delete_grupo/`,data,config
        ).then(() => {
            dispatch(successUsuario);
        }).catch(error => {
            dispatch(errorUsuarios({ status: error }));
        });
    }
};

export const fetchRolesAction = () => {
    return dispatch => {
        dispatch(getRoles);
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        return axios.get(
            `api/v1/conciliaciones/grupo/`,config
        ).then(r => {
            dispatch(gotRoles(r.data));
        }).catch(error => {
            dispatch(errorUsuarios({ status: error }));
        });
    }
};

const getUsuarios = obj => ({
    type: 'GET_USUARIOS',
    payload: obj
});

const gotUsuarios = obj => ({
    type: 'GOT_USUARIOS',
    payload: obj
});

const getRolesUsuarios = obj => ({
    type: 'GET_ROLESUSUARIOS',
    payload: obj
});

const gotRolesUsuarios = obj => ({
    type: 'GOT_ROLESUSUARIOS',
    payload: obj
});

const getListado = obj => ({
    type: 'GET_LISTADO',
    payload: obj
});

const gotListado = obj => ({
    type: 'GOT_LISTADO',
    payload: obj
});

const getRoles = {
    type: 'GET_ROLES',
};

const gotRoles = obj => ({
    type: 'GOT_ROLES',
    payload: obj
});

const getUsuario = obj => ({
    type: 'GET_USUARIO',
    payload: obj
});

const gotUsuario = obj => ({
    type: 'GOT_USUARIO',
    payload: obj
});

const requestUsuario = {
    type: 'REQUEST_USUARIO',
};

const successUsuario = {
    type: 'SUCCESS_USUARIO',
};

const errorUsuarios = obj => ({
    type: 'ERROR_USUARIOS',
    payload: obj
}); 