import React, { Component } from "react";
import { Route } from "react-router-dom";
import Bienvenida from "./Bienvenida";
import Admin from "./Pages/admin/Contenedor";
import User from "./Pages/Usuario/Contenedor";
import Transportadora from "./Pages/Transportadora/Contenedor"

export default class Rutas extends Component {
    render = () => {
        const { grupos } = this.props
        return <>
            <Route exact path="/" component={Bienvenida} />
            {grupos.map(a => a.name).includes("administrador") && <Route path="/administrador" component={Admin} />}
            {grupos.map(a => a.name).includes("colaborador") && <Route path="/falabella" component={User} />}
            {grupos.map(a => a.name).includes("transportadora") && <Route path="/transportadora" component={Transportadora} />}
        </>
    }
}