import React, {Component} from 'react'
import { SidebarConsumer } from "./Sidebar"
import Opcion from "./Opcion"

export default class Permisos extends Component {
    state = {
        collapsed: false,
    }
    roles = {
        administrador: {
            nombre: "Administrador", icon: "fa fa-user-cog",
            opciones: [
                {nombre: "Usuarios", slug: "admin_usuarios", uri: "/administrador/usuarios", icon: "fa fa-users-cog"},  
            ]
        },
        colaborador: {
            nombre: "Gestión de Registros", icon: "fas fa-dolly",
            opciones: [
                // {nombre: "Ejemplo Uno", slug: "ejemplouno", uri: "/usuario/ejemplouno", icon: "fas fa-chess-queen"},
                // {nombre: "Ejemplo Dos", slug: "ejemplodos", uri: "/usuario/ejemplodos", icon: "fas fa-chess-knight"},      
                {nombre: "Lista de Registros", slug: "lista_f11", uri: "/falabella/lista_f11", icon: "fas fa-th-list"},   
                {nombre: "Lista de Paquetes", slug: "lista_paquetes", uri: "/falabella/lista_paquetes", icon: "fas fa-th-list"},                    
                
            ]
        },
        transportadora: {
            nombre: "Gestión de Paquetes", icon: "fas fa-boxes",
            opciones: [
                {nombre: "Lista de Paquetes", slug: "lista_paquetes", uri: "/transportadora/lista_paquetes", icon: "fas fa-th-list"}, 
            ]
        },
    }

    render = () => (
        <SidebarConsumer>
            {
                ({grupos}) => (
                    <div className="list-group overflow-visible">
                        {
                            grupos.map(
                                (a, index) => (
                                    <div key={a.name}>
                                        <Opcion rol={this.roles[a.name]} collapsed={index !== 0}/>
                                    </div>
                                )
                            )
                        }
                    </div>
                )
            }
        </SidebarConsumer>
    )
}