import React, {Component} from 'react'
import {ClipLoader} from "react-spinners";

export default class Loading extends Component {
    render = () => {
        const {style} = this.props;
        return <div className="loading d-flex justify-content-center align-content-center flex-wrap" style={style}>
            <ClipLoader color="#cbd300" size={80}/>
        </div>

    };
}