import React, {Component, createContext} from 'react';
import Pagination from './Pagination';
import Paginas from './Paginas';
import Filter from './Filter';
import Table from './Table';
import THeader from './THeader';
import {normalizeText} from "../../helpers/Normalizer";


export const TableContext = createContext();

class VTable extends Component {
    static Filter = Filter;
    static Table = Table;
    static THeader = THeader;
    static Pagination = Pagination;
    static Paginas = Paginas;
    static defaultProps = {
        data: [],
        rowsPerPage: 10,
        filterFunction: (a, b) => normalizeText(Object.values(a).toString()).includes(normalizeText(b)),
        orderBy: null,
        orderDir: 'asc',
        fillBlanks: false,
        sortFunction: (a, b) => 0
    };

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: props.rowsPerPage,
            filterText: '',
            orderBy: props.orderBy,
            orderDir: props.orderDir,
            sortFunction: props.sortFunction
        };
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleOrderChange = this.handleOrderChange.bind(this);
        this.handleRowsPerPageChange = this.handleRowsPerPageChange.bind(this);
    }

    get defaultData() {
        const {data, orderBy} = this.props;
        const val = orderBy === null ? a => a : a => a[orderBy];
        return [...data].sort((a,b) => this.props.sortFunction(val(a), val(b))).map((a, defaultIndex) => ({...a, defaultIndex}));
    }

    get filteredData() {
        return this.defaultData.filter(a => this.props.filterFunction(a, this.state.filterText))
    }

    get pages() {
        const {filteredData, state: {rowsPerPage}} = this;
        return Math.ceil(filteredData.length/rowsPerPage);
    }

    get orderedData() {
        const {orderBy, orderDir} = this.state;
        if (orderBy === null) {
            return [...this.filteredData];
        }
        return [...this.filteredData].sort((a, b) => this.state.sortFunction(a[orderBy], b[orderBy], orderDir));
    }

    get pageData() {
        const {state: {page, rowsPerPage}, orderedData} = this;
        const inicio = page * rowsPerPage;
        return inicio >= orderedData.length ? [] : orderedData.slice(inicio, inicio + rowsPerPage);
    }

    handleOrderChange = (index, sortFunction, e) => {
        let {orderBy, orderDir} = this.state;
        orderDir = orderBy === index ? (orderDir === 'desc' ? 'asc' : 'desc') : 'desc';
        orderBy = index;
        const b = {currentTarget: {value: this.state.filterText}};
        this.setState({orderDir, orderBy, sortFunction}, () => this.handleFilterChange(b));
    };

    handleFilterChange = (e) => {
        const filterText = e.currentTarget.value;
        e = {currentTarget: {value: this.state.page}};
        this.setState({filterText}, () => this.handlePageChange(e));
    };

    handleRowsPerPageChange = (e) => {
        const rowsPerPage = parseInt(e.currentTarget.value);
        e = {currentTarget: {value: this.state.filterText}};
        this.setState({rowsPerPage}, () => this.handleFilterChange(e));
    };

    handlePageChange = (e) => {
        const {pages} = this;
        let page = parseInt(e.currentTarget.value);
        if (page >= pages) {
            page = pages - 1;
        }
        if(page < 0) {
            page = 0;
        }
        this.setState({
            page
        }, this.handleChange);
    };

    render() {
        const {
            handleFilterChange,
            handlePageChange,
            handleOrderChange,
            handleRowsPerPageChange,
            pages,
            state,
            pageData,
            props: {
                className,
                style,
                children,
                onRowFocus,
                fillBlanks,
            }
        } = this;
        return (
            <TableContext.Provider value={
                {...state, fillBlanks, pages, pageData, onRowFocus, handleFilterChange, handlePageChange, handleOrderChange, handleRowsPerPageChange}
            }>
                <div className={className} style={style}>
                    {children}
                </div>
            </TableContext.Provider>
        );
    }
}

export default VTable;

export const TableConsumer = TableContext.Consumer;
