import { local_token, local_refresh } from '../../../consts/consts'
import React, { Component } from 'react'
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import VTable from '../../table/VTable'
import axios from 'axios'
import Axios from 'axios'
import moment from "moment"
import { InputGroup, Breadcrumb,  Modal, Row, Container, Col, Button, Form, Alert, Tooltip, OverlayTrigger, Table } from "react-bootstrap"
import Noty from "noty"
import Workbook from 'react-excel-workbook'
import {fetchRefreshAction
} from "../../../actions/usuariosActions";

const ref = React.createRef();



class Historico extends Component {

    constructor(props) {
        super(props)
        this.state = {
            files: null,
            TRANSPORTADORA: '',
            TIPO: '',
            TIPIFICACION: '',
            FECHA_INICIO: '',
            FECHA_FIN: '',
            LOCALABT: '',
            user: '',
            f11_srx: [],
            listado: [],
            listadoTransportadoras: [],
            listadoTipos: [],
            listadoTipificaciones: [],
            listadoLocales: [],
            listadoUsuarios: [],
            cargando: false,
            bloquear: false,
            email: "",
            modalCorreo: false,
            modalsku: false,
            modalLiberar: false,
            SKU: "",
            UPC: "",
            DESCRIPCION: "",
            CANTIDAD: "",
            COSTO_REPOSICION: "",
            COSTO_TOTAL: "",
            listadosku: [{ID:"", F11: "", SKU: "", UPC: "", DESCRIPCION: "", CANTIDAD: "", COSTO_REPOSICION: "", COSTO_TOTAL: "" }],
            newlistadosku: [],

        }
    }

    handleFile(e) {
        // Getting the files from the input
        let files = e.target.files;
        console.log(files)
        this.setState({ files });
    }

    handleUpload(e) {
        let files = this.state.files;
        let formData = new FormData();
        //Adding files to the formdata
        //formData.append("file", files);
        formData.append("name", "Name");
        for (let index = 0; index < files.length; index++) {
            formData.append("file", files[index]);
        }
    }

    handleRefresh = () => {
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
        let config = {
            headers: {
                Authorization: AuthStr
            }
        }
        let formData = new FormData();
        formData.append("user", localStorage[local_refresh]);
        axios.post(`/api/v1/usuario/refresh/`,formData,config)
            .then((res) => {
                let data = res.data;
                localStorage.setItem(local_token, data.token);
            }).catch((e) => {
                localStorage.removeItem(local_token);
                localStorage.removeItem(local_refresh);
            })
        
    }

    handleChange = ({ currentTarget: { value, name, type } }) => {
        /*switch (type) {
            case "text": {
                value = value
            }

        }*/
        this.setState({ [name]: value });
        this.getUsuarios();
    };

    busqueda = () => {
        this.handleRefresh();
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        this.setState({ cargando: true, listado: "", bloquear: true })
        const obj = {
            "TRANSPORTADORA": this.state.TRANSPORTADORA,
            "TIPO": this.state.TIPO,
            "TIPIFICACION": this.state.TIPIFICACION,
            "LOCALABT": this.state.LOCALABT,
            "user": this.state.user,
            "FECHA_INICIO": moment(this.state.FECHA_INICIO),
            "FECHA_FIN": moment(this.state.FECHA_FIN)
        }

        axios.post("/api/v1/conciliaciones/busqueda/", obj,config)
            .then((res) => {
                this.setState({ listado: res.data, cargando: false })
                new Noty({
                    text: "Búsqueda exitosa",
                    type: "success",
                    timeout: 2000
                }).show()
            }).catch((e) => {
                if (e.response && e.response.data && e.response.data.Message) {
                    this.setState({ error: e.response.data.Message, cargando: false, bloquear: false })
                    new Noty({
                        text: this.state.error,
                        type: "error",
                        timeout: 1000
                    }).show()
                    this.clear()
                }
            })
    }

    componentDidMount() {
        this.getListadoF12();
        this.getUsuarios();
        this.getTipificaciones();
    }

    getListadoF12 = () => {
        this.handleRefresh();
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        axios.get("/api/v1/conciliaciones/f11/",config)
            .then((response) => {
                console.log(response.data)
                this.setState({ listado: response.data })
            })
            .catch((error) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 500,
                    modal: true,
                    text: 'No se lograron cargar los F12'
                }).show();
            })
    }
    getTransportadoras = () => {
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        axios.get("/api/v1/conciliaciones/transportadora/",config)
            .then((response) => {
                this.setState({ listadoTransportadoras: response.data })
            })
            .catch((error) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 500,
                    modal: true,
                    text: 'No se lograron cargar las transportadoras'
                }).show();
            })
    }
    getTipos = () => {
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        axios.get("/api/v1/conciliaciones/tipo/",config)
            .then((response) => {
                this.setState({ listadoTipos: response.data })
            })
            .catch((error) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 500,
                    modal: true,
                    text: 'No se lograron cargar los tipos'
                }).show();
            })
    }
    getTipificaciones = () => {
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        axios.get("/api/v1/conciliaciones/tipificacion/",config)
            .then((response) => {
                this.setState({ listadoTipificaciones: response.data })
            })
            .catch((error) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 500,
                    modal: true,
                    text: 'No se lograron cargar las tipificaciones'
                }).show();
            })
    }
    getLocales = () => {
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        axios.get("/api/v1/conciliaciones/localAbt/",config)
            .then((response) => {
                this.setState({ listadoLocales: response.data })
            })
            .catch((error) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 500,
                    modal: true,
                    text: 'No se lograron cargar los locales'
                }).show();
            })
    }
    getUsuarios = () => {
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        axios.get("/api/v1/usuario/usuario/",config)
            .then((response) => {
                this.setState({ listadoUsuarios: response.data })
            })
            .catch((error) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 500,
                    modal: true,
                    text: 'No se lograron cargar los usuarios'
                }).show();
            })
    }

    excel = () => {
        const data = this.state.listado
        return (
            <Workbook filename="F11_CONCILIACIONES.xlsx" element={
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Descargar Excel</Tooltip>}>
                    <span className="d-inline-block">
                        <Button style={{ pointerEvents: 'not-allowed' }} variant="success"><i className="fas fa-download"></i></Button>&nbsp;
                    </span>
                </OverlayTrigger>}>

                <Workbook.Sheet data={data} name="listado">
                    <Workbook.Column label="F11" value="F11" />
                    <Workbook.Column label="F12" value="F12" />
                    <Workbook.Column label="SUCURSAL" value="SUCURSAL" />
                    <Workbook.Column label="TRANSPORTADORA" value="TRANSPORTADORA" />
                    <Workbook.Column label="LOCALABT" value="LOCALABT" />
                    <Workbook.Column label="TIPO" value="TIPO" />
                    <Workbook.Column label="OBSERVACION" value="OBSERVACION" />
                    <Workbook.Column label="ESTADO" value="ESTADO" />
                    <Workbook.Column label="FECHA DESPACHO" value="FECHA" />
                    <Workbook.Column label="TIPIFICACION" value="TIPIFICACION" />
                    <Workbook.Column label="PAQUETE" value="PAQUETE" />
                    <Workbook.Column label="SOPORTE" value="SOPORTE" />
                    <Workbook.Column label="SOPORTE_VALIDO" value="SOPORTE_VALIDO" />
                </Workbook.Sheet>
            </Workbook>
        )
    }

    validarBusqueda = () => {
        if (this.state.FECHA_INICIO !== "" && this.state.FECHA_FIN !== "") {
            if (this.state.FECHA_FIN !== "" && this.state.FECHA_INICIO !== "" && this.state.FECHA_FIN < this.state.FECHA_INICIO) {
                new Noty({
                    text: "Fecha de inicio debe ser mayor a la de término.",
                    type: "error",
                    time: 3000
                }).show()
                this.setState({ FECHA_INICIO: '', FECHA_FIN: '' })
            }
            else {
                this.busqueda()
            }
        }
        else {
            new Noty({
                text: "Debe ingresar una fecha valida.",
                type: "error",
                time: 3000
            }).show()
        }
    }

    clear = () => {
        this.setState({ TRANSPORTADORA: '', TIPO: '', TIPIFICACION: '', FECHA_FIN: '', FECHA_INICIO: '', user: '', LOCALABT: '', cargando: false, bloquear: false,f11_srx: [], })
        this.getListadoF12();
    }

    liberarF11 = f11 => () => {
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        const url = "/api/v1/conciliaciones/consecutivo/" + f11 + "/"; 
        axios.get(url,config)
            .then((res) => {
                new Noty({
                    type: "success",    
                    text: "F11 liberado correctamente",
                    timeout: 1500
                }).show()
            })
            .catch((e) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 2000,
                    modal: true,
                    text: e.response.data.Message
                }).show();
            });
        const valor = this.state.modalLiberar
        this.setState({ modalLiberar: !valor });
    }

    handleModalSku = f11 => () => {
        const urls2 = "/api/v1/conciliaciones/f11/" + f11 + "/";
        var f_11 = []
        this.setState({f11_srx: f_11 })
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        axios.get(urls2,config)
            .then((response) => {
                f_11.push(response.data);
                this.setState({f11_srx: response.data })

            })
            .catch((error) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 500,
                    modal: true,
                    text: 'No se encontró el f11'
                }).show();
            });
        this.setState({ deshabilitar: false }); 
        var listadosku = []
        const urlbase = 'api/v1/conciliaciones/sku/'
        const url = urlbase + f11 + '/'
        Axios.get(url,config)
            .then((response) => {
                response.data.forEach((elem) => {
                    var registro = {ID: elem.id, F11: elem.F11, SKU: elem.SKU, UPC: elem.UPC, DESCRIPCION: elem.DESCRIPCION, CANTIDAD: elem.CANTIDAD, COSTO_REPOSICION: elem.COSTO_REPOSICION, COSTO_TOTAL: elem.COSTO_TOTAL }
                    listadosku.push(registro)

                })
                //this.setState({ listadosku: listadosku, f11_srx: f_11 })
                this.setState({ listadosku: listadosku })
            })
            .catch((error) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 500,
                    modal: true,
                    text: 'Ha ocurrido un error'
                }).show();
            })
        const valor = this.state.modalsku;
        //console.log(this.state.f11_srx)
        this.setState({ modalsku: !valor });

    }

    handleModalSku2 = f11 => () => {
        let files = this.state.files;

        var url_base = 'api/v1/conciliaciones/f11/'
        let formData = new FormData();
        let f_11 = this.state.f11_srx
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        formData.append("F11", f11); 
        formData.append("SOPORTE_VALIDO", this.state.f11_srx.SOPORTE_VALIDO); 
        formData.append("TIPIFICACION", this.state.f11_srx.TIPIFICACION); 
        formData.append("user", this.props.user.id); 
        if (files != null) {
            for (let index = 0; index < files.length; index++) {
                formData.append("SOPORTE", files[index]);
            }
        }
        else { formData.append("SOPORTE", ''); }
        //console.log(f11);
        const urls = url_base + f11 + '/'
        axios.put(urls, formData,config)
            .then(response => {
                //console.log(response.data);

            }).catch(error => {
                //console.log(error);
            })



        var urlbase = 'api/v1/conciliaciones/sku/'
        this.state.listadosku.forEach((elem) => {
            const obj = {
                F11: elem.F11,
                SKU: elem.SKU,
                UPC: elem.UPC,
                DESCRIPCION: elem.DESCRIPCION,
                CANTIDAD: elem.CANTIDAD,
                COSTO_REPOSICION: elem.COSTO_REPOSICION,
                COSTO_TOTAL: elem.COSTO_TOTAL,
            }
            axios.post(urlbase, obj,config)
                .then((res) => {
                    this.setState({ cargando: false })
                    this.getListadoF12();

                }).catch((e) => {
                    if (e.response && e.response.data && e.response.data.Message) {
                        this.setState({ error: e.response.data.Message, cargando: false, bloquear: false })
                        new Noty({
                            text: this.state.error,
                            type: "error",
                            timeout: 1000
                        }).show()
                        this.clear()
                    }
                })
        })
        //--------------------------------------------------------------------------

        const valor = this.state.modalsku;
        this.setState({ modalsku: !valor });
    }

    handleAddShareholder = idx => () => {
        this.setState({
            listadosku: this.state.listadosku.concat([{ID:"", F11: idx, SKU: "", UPC: "", DESCRIPCION: "", CANTIDAD: "", COSTO_REPOSICION: "", COSTO_TOTAL: "" }])
        });
    };

    handleRemoveShareholder = idx => () => {
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        let f_11 = this.state.listadosku.filter((s, sidx) => idx === sidx);
        let sku = f_11.map(({ ID }) => ID);
        axios.delete(
            `api/v1/conciliaciones/sku/${sku}/`, config
        ).then(() => {
            new Noty({
                text: "Se eliminó exitosamente",
                type: "success",
                timeout: 2000
            }).show()
        }).catch((e) => {
            if (e.response && e.response.data && e.response.data.Message) {
                this.setState({ error: e.response.data.Message, cargando: false, bloquear: false })
                new Noty({
                    text: this.state.error,
                    type: "error",
                    timeout: 1000
                }).show()
                this.clear()
            }
        });
        this.setState({
            listadosku: this.state.listadosku.filter((s, sidx) => idx !== sidx),
            newlistadosku: this.state.listadosku.filter((s, sidx) => idx !== sidx)
        });
    };

    handleShareholderskuChange = idx => evt => {
        const newlistadosku = this.state.listadosku.map((listadosku, sidx) => {
            if (idx !== sidx) return listadosku;
            return { ...listadosku, SKU: evt.target.value };
        });
        this.setState({ listadosku: newlistadosku, newlistadosku: newlistadosku });
    };

    handleShareholderUpcChange = idx => evt => {
        const newlistadosku = this.state.listadosku.map((listadosku, sidx) => {
            if (idx !== sidx) return listadosku;
            return { ...listadosku, UPC: evt.target.value };
        });
        this.setState({ listadosku: newlistadosku, newlistadosku: newlistadosku });
    };

    handleShareholderDescripcionChange = idx => evt => {
        const newlistadosku = this.state.listadosku.map((listadosku, sidx) => {
            if (idx !== sidx) return listadosku;
            return { ...listadosku, DESCRIPCION: evt.target.value };
        });

        this.setState({ listadosku: newlistadosku, newlistadosku: newlistadosku });
    };

    handleShareholderCantidadChange = idx => evt => {
        if (evt.target.value.match("^[1-9][0-9]*$|^$") != null) {
            const newlistadosku = this.state.listadosku.map((listadosku, sidx) => {
                if (idx !== sidx) return listadosku;
                var total = listadosku.COSTO_REPOSICION * evt.target.value;
                return { ...listadosku, CANTIDAD: evt.target.value, COSTO_TOTAL: total };
            });

            this.setState({ listadosku: newlistadosku, newlistadosku: newlistadosku });
        }
    };

    handleShareholderCostoRepoChange = idx => evt => {
        if (evt.target.value.match("^[1-9][0-9]*$|^$") != null) {
            const newlistadosku = this.state.listadosku.map((listadosku, sidx) => {
                if (idx !== sidx) return listadosku;
                var total = listadosku.CANTIDAD * evt.target.value;
                return { ...listadosku, COSTO_REPOSICION: evt.target.value, COSTO_TOTAL: total };
            });

            this.setState({ listadosku: newlistadosku, newlistadosku: newlistadosku });
        }
    };
    handleShareholderCostoTotalChange = idx => evt => {
        if (evt.target.value.match("^[1-9][0-9]*$|^$") != null) {
            const newlistadosku = this.state.listadosku.map((listadosku, sidx) => {
                if (idx !== sidx) return listadosku;
                return { ...listadosku, COSTO_TOTAL: evt.target.value };
            });

            this.setState({ listadosku: newlistadosku, newlistadosku: newlistadosku });
        }
    };
    handleSoporteValido = idx => evt => {
        if (this.state.f11_srx.SOPORTE_VALIDO === true) {
            const listado = this.state.f11_srx
            listado.SOPORTE_VALIDO = false      
            this.setState({ f11_srx: listado })
        }
        else {
            const listado = this.state.f11_srx
            listado.SOPORTE_VALIDO = true           
            this.setState({ f11_srx: listado })
        }
    };
    handleShareholderTipificacion = idx => evt => {
        const listado = this.state.f11_srx;
        listado.TIPIFICACION = evt.target.value        
        this.setState({ f11_srx: listado });
    };
    render() {
        const {  state: { listadoTransportadoras, listadoTipos, listadoTipificaciones, listadoLocales, listadoUsuarios,  f11_srx }, handleModalSku, handleModalSku2, handleRemoveShareholder, handleAddShareholder, handleSoporteValido } = this
        /*const options = {
            orientation: 'landscape'
        };*/
        return (
            <>
                <div>
                    <Breadcrumb>
                        <Breadcrumb.Item active><Link to="/">Inicio</Link></Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            Lista Registros
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Lista F11s</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Container className="p-2">
                    <Container style={{ display: "content" }}>
                        <h4>
                            <i className="fas fa-database"></i> Listado F11
                        </h4>
                        <br />
                        <Form.Row>
                            <Col md={4}>
                                <Form.Label>Desde</Form.Label>
                                <Form.Control
                                    type="date"
                                    // disabled={this.state.TRANSPORTADORA === ''}
                                    name="FECHA_INICIO"
                                    value={this.state.FECHA_INICIO}
                                    disabled={this.state.bloquear}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Col>
                            <Col md={4}>
                                <Form.Label>Hasta</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="FECHA_FIN"
                                    value={this.state.FECHA_FIN}
                                    disabled={this.state.bloquear}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row className="mt-3">
                            <Col xs={4}>
                                <Form.Label>Tipificación</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="TIPIFICACION"
                                    value={this.state.TIPIFICACION}
                                    disabled={this.state.bloquear}
                                    onChange={this.handleChange}
                                    required >
                                    <option value="">Seleccione una Tipificación</option>
                                    {listadoTipificaciones.map((elem, key) => {
                                        return <option key={key} value={elem.NAME}>{elem.NAME}</option>;
                                    })}
                                </Form.Control>
                            </Col>                       
                            <Col xs={4}>
                                <Form.Label>Usuario</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="user"
                                    value={this.state.user}
                                    disabled={this.state.bloquear}
                                    onChange={this.handleChange}
                                    required >
                                    <option value="">Seleccione un Usuario</option>
                                    {listadoUsuarios.map((elem, key) => {
                                        return <option key={key} value={elem.nombres+ ' ' +elem.apellidos}>{elem.nombres+ ' ' +elem.apellidos}</option>;
                                    })}
                                </Form.Control>
                            </Col>
                            <Col xs={4} className="justify-content-end">
                                <label>&nbsp;&nbsp;</label>
                                <br></br>
                                <OverlayTrigger overlay={<Tooltip id="button-tooltip">Buscar</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button variant="primary" onClick={this.validarBusqueda}><i className={this.state.cargando ? "fa fa-spinner fa-spin fa-1x fa-fw" : "fas fa-search"}></i></Button>&nbsp;
                                    </span>
                                </OverlayTrigger>
                                {this.state.listado.length === 0 ?
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Descargar Excel</Tooltip>}>
                                        <span className="d-inline-block">
                                            <Button style={{ pointerEvents: 'none' }} disabled variant="success"><i className="fas fa-download"></i></Button>&nbsp;
                                        </span>
                                    </OverlayTrigger> :
                                    this.excel()
                                }
                                <OverlayTrigger overlay={<Tooltip id="button-tooltip">Limpiar</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button variant="warning" onClick={this.clear}><i className="fas fa-backspace"></i></Button>
                                    </span>
                                </OverlayTrigger>
                            </Col>
                        </Form.Row>
                    </Container>
                    <br />
                    <div>
                        {this.state.listado.length > 0 ?
                            <Alert variant="success">{"Se han encontrado: " + this.state.listado.length + " registros"}</Alert>
                            :
                            undefined
                        }
                        <VTable data={this.state.listado} className="position-relative" orderBy="FECHA_REPARTO" rowsPerPage={20} orderDir="desc" sticky={false}>
                            {this.state.listado.length > 0 ?
                                <nav className="form-inline justify-content-end">
                                    <VTable.Filter className="justify-content-end form-control mb-2"
                                        placeHolder="Buscar..." />
                                </nav>
                                :
                                undefined
                            }
                            <VTable.Table className="table table-bordered table-hover table-sm text-center">
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.F11)}>F11</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.F12)}>F12</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.TRANSPORTADORA)}>TRANSPORTADORA</VTable.THeader>
                                {/* <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.TIPO)}>CIUDAD ENTREGA</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.TIPIFICACION)}>REGION ENTREGA</VTable.THeader> */}
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.user)}>USUARIO</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.OBSERVACION)}>OBSERVACIONES</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "2%" }} index="FECHA" formatter={(a) => (moment(a).format("DD-MM-YYYY HH:mm"))}>FECHA DESPACHO</VTable.THeader>
                                {/*<VTable.THeader className="text-center" style={{ width: "2%" }} index="FECHA_REPARTO" formatter={(a) => (moment(a).format("DD-MM-YYYY HH:mm"))}>F. REPARTO</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "2%" }} index="ENTREGA_ESTIMADA" formatter={(a) => (moment(a).format("DD-MM-YYYY HH:mm"))}>F. ENTREGA ESTIMADA</VTable.THeader> */}
                                {/* <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.TRANSPORTE)}>Transporte</VTable.THeader> */}
                                <VTable.THeader className="text-center" style={{ width: "1%", }} formatter={
                                    a => [
                                        <div className="text-center" key="1">

                                            <Button title="Detalle" variant="primary" className="btn-sm mt-1 p-1 mb-2 mr-1" disabled={this.state.enviar || this.state.bajada_documento} icon="fa fa-users" onClick={handleModalSku(a.F11)} ><i className="far fa-eye" style={{ fontSize: 18 }} /></Button>

                                            {(() => {
                                                if (a.PAQUETE === false) {
                                                    return (
                                                        <Button title="Liberar" variant="warning" className="btn-sm mt-1 p-1 mb-2 mr-1" icon="fa fa-users" onClick={() => this.setState({ modalLiberar: true, datos: a })} ><i className="far fa-paper-plane" style={{ fontSize: 18 }} /></Button>
                                                    )
                                                }
                                                if (a.PAQUETE === true) {
                                                    return (
                                                        <span className="badge badge-success mr-1">Liberado</span>
                                                    )
                                                }
                                            })()}


                                            {(() => {
                                                if (a.SOPORTE != null) {
                                                    if (a.SOPORTE_VALIDO === false) {
                                                        return (
                                                            <a title="Ver Soporte" target="_blank" rel="noopener noreferrer" href={a.SOPORTE} className="btn-danger btn-sm mt-1 p-1 mb-2 mr-1" icon="fa fa-users"><i className="fas fa-file-export" style={{ fontSize: 18 }} /></a>
                                                        )
                                                    }
                                                    if (a.SOPORTE_VALIDO === true) {
                                                        return (
                                                            <a title="Ver Soporte" target="_blank" rel="noopener noreferrer" href={a.SOPORTE} className="btn-success btn-sm mt-1 p-1 mb-2 mr-1" icon="fa fa-users"><i className="fas fa-file-export" style={{ fontSize: 18 }} /></a>
                                                        )
                                                    }
                                                }
                                            })()}


                                        </div>
                                    ]
                                }>
                                    ACCIONES
                                </VTable.THeader>
                            </VTable.Table>
                            <nav className="d-flex justify-content-end">
                                <VTable.Pagination className="" />
                            </nav>
                        </VTable>
                    </div>
                </Container>
                {
                    this.state.f11_srx &&
                        <Modal show={this.state.modalsku} onHide={() => this.setState({ modalsku: false })} dialogClassName="my-modal" size="xl" overflow="auto">
                            <Modal.Header closeButton>
                                <Modal.Title className="mx-4">Detalle F11</Modal.Title>
                            </Modal.Header>                            
                            <Modal.Body>
                                <Row>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>F11</b></h5>
                                        <h7>{this.state.f11_srx.F11}</h7>
                                        <hr></hr>
                                    </Col>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>F12</b></h5>
                                        <h7>{this.state.f11_srx.F12}</h7>
                                        <hr></hr>
                                    </Col>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>SUCURSAL</b></h5>
                                        <h7>{this.state.f11_srx.SUCURSAL}</h7>
                                        <hr></hr>
                                    </Col>
                                    
                                </Row>
                                <Row>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>TRANSPORTADORA</b></h5>
                                        <h7>{this.state.f11_srx.TRANSPORTADORA}</h7>
                                        <hr></hr>
                                    </Col>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>LOCALABT</b></h5>
                                        <h7>{this.state.f11_srx.LOCALABT}</h7>
                                        <hr></hr>
                                    </Col>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>TIPO</b></h5>
                                        <h7>{this.state.f11_srx.TIPO}</h7>
                                        <hr></hr>
                                    </Col>
                                    
                                </Row>
                                <Row>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>OBSERVACION</b></h5>
                                        <h7>{this.state.f11_srx.OBSERVACION}</h7>
                                        <hr></hr>
                                    </Col>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>ESTADO</b></h5>
                                        <h7>{this.state.f11_srx.ESTADO}</h7>
                                        <hr></hr>
                                    </Col>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>FECHA</b></h5>
                                        <h7>{this.state.f11_srx.FECHA}</h7>
                                        <hr></hr>
                                    </Col>
                                    
                                </Row>
                                <Row>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>PAQUETE</b></h5>                                        
                                        { 
                                        this.state.f11_srx.PAQUETE ===false?
                                        <h7>SIN ENVIAR</h7>
                                        :
                                        <h7>ENVIADO</h7>
                                        }
                                        <hr></hr>
                                    </Col>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>USUARIO</b></h5>
                                        <h7>{this.state.f11_srx.user}</h7>
                                        <hr></hr>
                                    </Col>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>TIPIFICACION</b></h5>
                                        <Form.Group>
                                            <Form.Control
                                                as="select"
                                                name="TIPIFICACION"
                                                value={this.state.f11_srx.TIPIFICACION}
                                                onChange={this.handleShareholderTipificacion(this.state.f11_srx.F11)}
                                                required >
                                                {listadoTipificaciones.map((elem, key) => {
                                                    return <option key={key} value={elem.NAME}>{elem.NAME}</option>;
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                        <hr></hr>
                                    </Col>                                    
                                </Row>
                                <Row>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>SOPORTE</b></h5>                                        
                                        { 
                                        this.state.f11_srx.SOPORTE == null ?
                                        <Form.Group>
                                            <Form.Control
                                                as="input"
                                                type="file"
                                                accept="image/png, .jpeg, .jpg"
                                                onChange={(em) => this.handleFile(em)}
                                                required>
                                            </Form.Control>
                                        </Form.Group>
                                        :
                                        <h7><a href={this.state.f11_srx.SOPORTE} >Ver soporte</a></h7>
                                        }
                                        <hr></hr>
                                    </Col>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>SOPORTE VALIDO</b></h5>
                                        <InputGroup >
                                            <InputGroup.Prepend>
                                                <InputGroup.Checkbox checked={this.state.f11_srx.SOPORTE_VALIDO} value={this.state.f11_srx.SOPORTE_VALIDO} id={this.state.f11_srx.F11} onClick={handleSoporteValido(this.state.f11_srx.F11)}></InputGroup.Checkbox>
                                            </InputGroup.Prepend>
                                        </InputGroup>
                                        <hr></hr>
                                    </Col>                                    
                                </Row>

                                <br></br>
                                
                                <Table responsive >
                                    <thead>
                                        <tr>
                                            <th className="text-center" width="3%">#</th>
                                            <th className="text-center" width="20%">SKU</th>
                                            <th className="text-center" width="20%">UPC</th>
                                            <th className="text-center" width="20%">Descripción</th>
                                            <th className="text-center" width="3%">Cantidad</th>
                                            <th className="text-center" width="20%">Costo reposicion</th>
                                            <th className="text-center" width="30%">Costo total</th>
                                            <th className="text-center" width="5%">Eliminar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.listadosku.map((elem, idx) => (
                                            <tr>
                                                <td>{idx + 1}</td>
                                                <td>
                                                    <Form.Group>
                                                        <Form.Control
                                                            as="input"
                                                            maxLength={50}
                                                            name="sku"
                                                            type="number"
                                                            value={elem.SKU}
                                                            onChange={this.handleShareholderskuChange(idx)}
                                                            required>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Group>
                                                        <Form.Control
                                                            as="input"
                                                            maxLength={50}
                                                            type="number"
                                                            name="UPC"
                                                            value={elem.UPC}
                                                            onChange={this.handleShareholderUpcChange(idx)}
                                                            required>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Group>
                                                        <Form.Control
                                                            as="input"
                                                            maxLength={200}
                                                            name="DESCRIPCION"
                                                            type="text"
                                                            value={elem.DESCRIPCION}
                                                            onChange={this.handleShareholderDescripcionChange(idx)}
                                                            required>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Group>
                                                        <Form.Control
                                                            as="input"
                                                            maxLength={5}
                                                            name="CANTIDAD"
                                                            type="number"
                                                            value={elem.CANTIDAD}
                                                            onChange={this.handleShareholderCantidadChange(idx)}
                                                            required>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Group>
                                                        <Form.Control
                                                            as="input"
                                                            maxLength={50}
                                                            name="COSTO_REPOSICION"
                                                            type="number"
                                                            value={elem.COSTO_REPOSICION}
                                                            onChange={this.handleShareholderCostoRepoChange(idx)}
                                                            required>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Group>
                                                        <Form.Control
                                                            as="input"
                                                            maxLength={50}
                                                            disabled='True'
                                                            name="COSTO_TOTAL"
                                                            type="number"
                                                            value={elem.COSTO_TOTAL}
                                                            onChange={this.handleShareholderCostoTotalChange(idx)}
                                                            required>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </td>
                                                <td><Button variant="danger" onClick={handleRemoveShareholder(idx)}>-</Button></td>
                                            </tr>))}
                                    </tbody>

                                </Table>
                                <Button className="text-center" variant="success" onClick={handleAddShareholder(this.state.f11_srx.F11)}>Agregar Sku</Button>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={(handleModalSku2(this.state.f11_srx.F11))}>Guardar</Button>
                            </Modal.Footer>
                        </Modal>
                    }
                {
                    this.state.datos &&
                    <Modal show={this.state.modalCorreo} onHide={() => this.setState({ modalCorreo: false })} dialogClassName="modal-correo" size="xl" overflow="auto">
                        <Modal.Header closeButton>
                            <Modal.Title className="mx-4">Detalle F12</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div ref={ref}>
                                <Container>
                                    <div>
                                        <h1>Select your files</h1>
                                        <input
                                            type="file"
                                            multiple="multiple"  //To select multiple files
                                            onChange={(e) => this.handleFile(e)}
                                        />
                                        <button onClick={(e) => this.handleUpload(e)}
                                        >Send Files</button>
                                    </div>
                                    <Table responsive >
                                        <thead>
                                            <tr>
                                                <th className="text-center" width="3%">F12</th>
                                                <th className="text-center" width="20%">GUIA</th>
                                                <th className="text-center" width="20%">TRANSPORTADORA</th>
                                                <th className="text-center" width="7%">PRIMER_EN_RUTA</th>
                                                <th className="text-center" width="7%">FECHA_REPARTO</th>
                                                <th className="text-center" width="7%">TIPO</th>
                                                <th className="text-center" width="20%">TIPIFICACION</th>
                                                <th className="text-center" width="20%">ABASTECIMIENTO MACRO</th>
                                                <th className="text-center" width="5%">LOCAL ABAS_MACRO</th>
                                                <th className="text-center" width="5%">METODO DE DESPACHO CORP</th>
                                                <th className="text-center" width="5%">ORIGEN</th>
                                                <th className="text-center" width="5%">ULTIMA NOVEDAD</th>
                                                <th className="text-center" width="5%">ENTREGA ESTIMADA</th>
                                                <th className="text-center" width="5%">RANGO VENCIMIENTO </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th className="text-center" width="3%">{this.state.datos.F12}</th>
                                                <th className="text-center" width="20%">{this.state.datos.GUIA}</th>
                                                <th className="text-center" width="20%">{this.state.datos.TRANSPORTADORA}</th>
                                                <th className="text-center" width="7%">{this.state.datos.PRIMER_EN_RUTA}</th>
                                                <th className="text-center" width="7%">{this.state.datos.FECHA_REPARTO}</th>
                                                <th className="text-center" width="7%">{this.state.datos.TIPO}</th>
                                                <th className="text-center" width="20%">{this.state.datos.TIPIFICACION}</th>
                                                <th className="text-center" width="20%">{this.state.datos.ABASTECIMIENTO_MACRO}</th>
                                                <th className="text-center" width="5%">{this.state.datos.LOCALABT}</th>
                                                <th className="text-center" width="5%">{this.state.datos.user}</th>
                                                <th className="text-center" width="5%">{this.state.datos.ORIGEN}</th>
                                                <th className="text-center" width="5%">{this.state.datos.ULTIMA_NOVEDAD}</th>
                                                <th className="text-center" width="5%">{this.state.datos.ENTREGA_ESTIMADA}</th>
                                                <th className="text-center" width="5%">{this.state.datos.RANGO_VENCIMIENTO_1}</th>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Container>
                            </div>
                        </Modal.Body>
                    </Modal>
                }
                {
                    this.state.datos &&
                    <Modal show={this.state.modalLiberar} onHide={() => this.setState({ modalLiberar: false })} dialogClassName="modal-correo" overflow="auto">
                        <Modal.Header closeButton>
                            <Modal.Title className=" text-center mx-4">¿Está seguro de liberar el F11: {this.state.datos.F11}?</Modal.Title>
                        </Modal.Header>
                        <Modal.Footer>
                            <Button className="text-center" variant="success" onClick={(this.liberarF11(this.state.datos.F11))}>Liberar</Button>
                            <Button onClick={() => this.setState({ modalLiberar: false })}>Cerrar</Button>
                        </Modal.Footer>
                    </Modal>
                }
            </>
        )
    }
}

const mapStateToProps = store => ({
    user: store.auth.User,

})


export default connect(mapStateToProps)(Historico)