export const toggleSidebarAction = () => dispatch =>  {
    dispatch(toggleSidebar());
};

export const addMigaAction = (miga) => dispatch =>  {
    dispatch(addMiga(miga));
};

export const selectMenuAction = (menu) => dispatch =>  {
    dispatch(selectMenu({menu: menu}));
};

const toggleSidebar = obj => ({
    type: 'TOGGLE_SIDEBAR',
    payload: obj
});

const selectMenu = obj => ({
    type: 'MENU_SELECTED',
    payload: obj
});

const addMiga = obj => ({
    type: 'ADD_MIGA',
    payload: obj
});