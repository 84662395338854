import React, { Component } from "react"
import Sidebar from "./sidebar/Sidebar"
import { userLogin, userLogout } from "../actions/authActions"
import { connect } from "react-redux"
import Rutas from "./Rutas"
import { Col, Row } from "react-bootstrap"
import Barra from "./navbar/Barra"


export class Inicio extends Component {

    render = () => {
        return (
            <Row noGutters className="h-100 overflow-hidden" style={{ paddingTop: '60px' }}>
                <Barra user={this.props.user} />
                <Sidebar user={this.props.user} grupos={this.props.grupo} onLogout={this.props.userLogout} collapsed={this.props.collapsed}>
                <Sidebar.Permisos/>
                </Sidebar>
                <Col className="flex-grow-1 h-100 overflow-auto">
                    <Row noGutters className="align-items-stretch">
                        <Col className="overflow-visible">
                            <Rutas user={this.props.user}  grupos={this.props.grupo} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = store => ({
    user: store.auth.User,
    grupo: store.auth.Grupos,
    collapsed: store.menu.Collapsed
})

const mapDispatchToProps = dispatch => ({
    userLogin: (obj) => dispatch(userLogin(obj)),
    userLogout: (obj) => dispatch(userLogout(obj)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Inicio)
