import { local_token } from '../../../consts/consts'
import React, { Component} from 'react'
import { connect } from "react-redux"
import { Link} from "react-router-dom"
import VTable from '../../table/VTable'
import axios from 'axios'
import { Breadcrumb, Modal, Container,Button,  Alert, Tooltip, OverlayTrigger } from "react-bootstrap"
import Noty from "noty"
import Workbook from 'react-excel-workbook'

//const ref = React.createRef();


class Lista_Paquetes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            listado: [],
            cargando: false,
            bloquear: false,
            email: "",
            modalLiberar: false,
        }
    }

    handleChange = ({ currentTarget: { value, name, type } }) => {
        /*switch (type) {
            case "text": {
                value = value
            }
        }*/
        this.setState({ [name]: value });
        this.getListadoF12();
    };

    componentDidMount() {
        this.getListadoF12();
    };

    getListadoF12 = () => {
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        axios.get("/api/v1/conciliaciones/listaConsecutivo/",config)
            .then((response) => {
                this.setState({ listado: response.data })
            })
            .catch((error) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 500,
                    modal: true,
                    text: 'No se lograron cargar los F12'
                }).show();
            })
    };

    liberarF11 = id => () => {
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        const url = "/api/v1/conciliaciones/paquete/" + id + "/";
        axios.put(url,'',config)
            .then((res) => {
                this.getListadoF12();
                new Noty({
                    type: "success",
                    text: "Paquete de F11s liberado correctamente",
                    timeout: 1500
                }).show()
                
            })
            .catch((e) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 2000,
                    modal: true,
                    text: e.response.data.message
                }).show();
            });
        const valor = this.state.modalLiberar
        this.setState({ modalLiberar: !valor });
    };

    excel = () => {
        const data = this.state.listado
        return (
            <Workbook filename="PAQUETES_F11_CONCILIACIONES.xlsx" element={
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Descargar Excel</Tooltip>}>
                    <span className="d-inline-block">
                        <Button style={{ pointerEvents: 'not-allowed' }} variant="success"><i className="fas fa-download"></i></Button>&nbsp;
                    </span>
                </OverlayTrigger>}>

                <Workbook.Sheet data={data} name="listado">
                    <Workbook.Column label="CÓDIGO" value="F11" />
                </Workbook.Sheet>
            </Workbook>
        )
    }

    render() {
        /*const { props: { match }, state: { listado }, } = this
        const options = {
            orientation: 'landscape'
        };*/
        return (
            <>
                <div>
                    <Breadcrumb>
                        <Breadcrumb.Item active><Link to="/">Inicio</Link></Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            Paquetes
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Lista</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Container className="p-2">
                    <Container style={{ display: "content" }}>
                        <h4><i className="fas fa-database"></i> Listado de Paquetes F11s</h4>
                        <hr/>
                        <br />
                    </Container>
                    <br />
                    <div>
                        {this.state.listado.length > 0 ?
                            <Alert variant="success">{"Se han encontrado: " + this.state.listado.length + " registros"}</Alert>
                            :
                            undefined
                        }
                        <VTable data={this.state.listado} className="position-relative" orderBy="FECHA_REPARTO" rowsPerPage={20} orderDir="desc" sticky={false}>
                            {this.state.listado.length > 0 ?
                                <nav className="form-inline justify-content-end">
                                    <VTable.Filter className="justify-content-end form-control mb-2"
                                        placeHolder="Buscar..." />
                                </nav>
                                :
                                undefined
                            }
                            <VTable.Table className="table table-bordered table-hover table-sm text-center">
                            <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (`${a.ESTADO}`)}>ESTADO</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (`${a.CODIGO}-${a.TRANSPORTADORA}`)}>CÓDIGO</VTable.THeader>

                                <VTable.THeader className="text-center" style={{ width: "1%", }} formatter={
                                    a => [
                                        <div className="text-center" key="1">
                                            {(() => {
                                                if (a.ENVIO === false) {
                                                    return (
                                                        <Button title="Enviar a Transportadora" variant="warning" className="btn-sm mt-1 p-1 mb-2 mr-1" icon="fa fa-users" onClick={() => this.setState({ modalLiberar: true, datos: a })} ><i className="far fa-paper-plane" style={{ fontSize: 18 }} /></Button>
                                                    )
                                                }
                                                if (a.ENVIO === true) {
                                                    return (
                                                        <span class="badge badge-success mr-1">Enviado</span>
                                                    )
                                                }
                                            })()}
                                            <Link title="Ver Lista F11" to={`/falabella/lista_f11_paquete/${a.id}`}><Button title="Ver Lista F11" variant="success" className="btn-sm mt-1 p-1 mb-2 mr-1" ><i className="fas fa-clipboard-list" style={{ fontSize: 18 }} /></Button></Link>
                                            <Link title="Ver Conciliaciones" to={`/falabella/lista_conciliaciones/${a.id}`}><Button title="Ver Conciliaciones" variant="success" className="btn-sm mt-1 p-1 mb-2 mr-1" ><i className="fas fa-hands-helping" style={{ fontSize: 18 }} /></Button></Link>
                                            <Link title="Gestionar Conciliaciones" to={`/falabella/lista_sub_conciliaciones/${a.id}`}><Button title="Gestionar Conciliaciones" variant="success" className="btn-sm mt-1 p-1 mb-2 mr-1" ><i className="fas fa-cogs" style={{ fontSize: 18 }} /></Button></Link>
                                        </div>
                                    ]
                                }>
                                    ACCIONES
                                </VTable.THeader>
                            </VTable.Table>
                            <nav className="d-flex justify-content-end">
                                <VTable.Pagination className="" />
                            </nav>
                        </VTable>
                    </div>
                </Container>
                {
                    this.state.datos &&
                    <Modal show={this.state.modalLiberar} onHide={() => this.setState({ modalLiberar: false })} dialogClassName="modal-correo" overflow="auto">
                        <Modal.Header closeButton>
                            <Modal.Title classname=" text-center mx-4">¿Está seguro de enviar el Paquete: {this.state.datos.CODIGO}-{this.state.datos.TRANSPORTADORA}?</Modal.Title>
                        </Modal.Header>
                        <Modal.Footer>
                            <Button class="text-center" variant="success" onClick={(this.liberarF11(this.state.datos.id))}>Enviar</Button>
                            <Button onClick={() => this.setState({ modalLiberar: false })}>Cerrar</Button>
                        </Modal.Footer>
                    </Modal>
                }
            </>
        )
    }
}

const mapStateToProps = store => ({
    user: store.auth.User,

})

export default connect(mapStateToProps)(Lista_Paquetes)