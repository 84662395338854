import React, { useState } from 'react';
//import { InputGroup, Card, Modal, Container, CardDeck, ListGroup, Row, Col, Form } from "react-bootstrap"


export default function PasswordInput(props) {
const [show, setShow] = useState(false);
const toggleShow = () => {
setShow(!show);
};
return (
<>
<div className="input-group">
<input  tabIndex="1" {...props} className="form-control border-right-0" type={show ? "text" : "password"}/>
<span className="input-group-append ">
<button className="form-control border-right-1" type="button" onClick={toggleShow}>
<span className="fa fa-eye" ></span>
</button>
</span>


</div>
</>
);
} 



