import React, { Component, useState } from 'react'
import { connect } from "react-redux"
import Noty from 'noty'
import VTable from "../../../table/VTable"
import { Button, Col, Container, Modal, Breadcrumb, OverlayTrigger, Tooltip, Alert } from "react-bootstrap"
import { Link, Route, Router } from "react-router-dom"
import Loading from "../../../Loading"
import Formulario from "./Formulario"
import {
    createRolAction,
    createUsuarioAction, deleteRolAction,
    deleteUsuarioAction, fetchRolesAction,
    activarUsuarioAction,
    fetchUsuariosAction,
    updateUsuarioAction,
    listadoUsuariosAction,
    RolesUsuariosAction
} from "../../../../actions/usuariosActions";

class Tabla extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
        }
    }

    componentDidMount = () => {
        this.props.fetchRolesUsuarios();
        this.props.fetchRoles();
        this.props.fetchUsuarios();
        this.props.fetchListado();
    }

    render = () => {
        const { props: { match }, tablaUsuarios, modal } = this

        return <>
        <Breadcrumb>
                    <Breadcrumb.Item active>
                        <Link to="/">Inicio</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Administrador
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Usuarios
                    </Breadcrumb.Item>
                </Breadcrumb>
            <Container>
            <Col>
            <h4 style={{ marginLeft: "40px" }}>
                    <i className="fas fa-users"></i> Usuarios
                       </h4>
            </Col>
                <Router history={this.props.history}>
                    <Route path={`${match.url}`} component={tablaUsuarios} />
                    <Route forceReload path={
                        [
                            `${match.url}/agregarUsuario`,
                            `${match.url}/:id/editarUsuario`,
                        ]}
                        component={modal} />
                </Router>
            </Container>

        </>
    }


    handleClickRol = (rut, rol) => {
        const { props: { createRol, deleteRol, listado } } = this
        const user = listado.find(x => x.RUT == rut)
        const usuario = this.props.rolesUsuarios.find(x => x.RUT == rut && x.groups === rol)
        console.log(usuario)
        console.log(rut)
        console.log(user)
        console.log(listado)
        
        if (usuario == null) {
            console.log('crea')
            createRol(user.id, rol).then(() =>
                this.props.fetchRolesUsuarios())
        } else {
            console.log('quita')
            deleteRol(user.id, rol).then(() =>
                this.props.fetchRolesUsuarios())
        }
    }


    deleteUsuario = usuario => {
        const { props: { fetchUsuarios, deleteUsuario } } = this;
        const noty = new Noty({
            text: "¿Desea eliminar al usuario?",
            buttons: [
                Noty.button('Si', 'btn btn-success', () => {
                    deleteUsuario(usuario).then(() => {
                        fetchUsuarios();
                        new Noty({
                            text: "Se ha eliminado el usuario",
                            type: "success",
                        }).show();
                        this.props.fetchRolesUsuarios()
                    });
                    noty.close();
                }),
                Noty.button('No', 'btn btn-error', () => {
                    noty.close();
                }),
            ]
        });
        noty.show();
    };

    activarUsuario = usuario => {
        const { props: { fetchUsuarios, activarUsuario } } = this;
        const noty = new Noty({
            text: "¿Desea activar al usuario?",
            buttons: [
                Noty.button('Si', 'btn btn-success', () => {
                    activarUsuario(usuario).then(() => {
                        fetchUsuarios();
                        new Noty({
                            text: "Se ha activado el usuario",
                            type: "success",
                        }).show(); 
                        this.props.fetchRolesUsuarios()
                    });
                    noty.close();
                }),
                Noty.button('No', 'btn btn-error', () => {
                    noty.close();
                }),
            ]
        });
        noty.show();
    };


    tablaUsuarios = () => {
        const { props: { match, loadingUsuario, usuarios } } = this
        return (
            <div>
                {
                    loadingUsuario ?
                        <Loading /> :
                        <Container>
                            <VTable rowsPerPage={10} data={usuarios} className="position-relative" key="tablaUsuarios">
                                <nav className="form-inline justify-content-end  mb-2">
                                    <OverlayTrigger overlay={<Tooltip id="button-tooltip">Agregar</Tooltip>}>
                                        <span className="d-inline-block">
                                            <Link className="mx-2 btn btn-success"
                                                to={`${match.url}/agregarUsuario`}>
                                                <i className="fas fa-user-plus" style={{ fontSize: 20 }}></i>
                                            </Link></span>
                                    </OverlayTrigger>
                                    <VTable.Filter className="justify-content-end form-control"
                                        placeHolder="Buscar..." />
                                </nav>
                                <VTable.Table rows className="table table-bordered table-hover table-sm text-left">
                                    <VTable.THeader style={{ width: "1%" }} formatter={a => a.defaultIndex + 1}>N°</VTable.THeader>
                                    <VTable.THeader style={{ width: "8%" }} formatter={(a) => (a.RUT)}>RUT</VTable.THeader>
                                    <VTable.THeader style={{ width: "8%" }} formatter={(a) => (a.nombres)}>NOMBRES</VTable.THeader>
                                    <VTable.THeader style={{ width: "8%" }} formatter={(a) => (a.apellidos)}>APELLIDOS</VTable.THeader>
                                    <VTable.THeader style={{ width: "8%" }} formatter={(a) => (a.email)}>EMAIL</VTable.THeader>
                                    <VTable.THeader className="text-center" style={{ width: "1%", }} formatter={
                                        a => [
                                            <div className="text-center" key="1">
                                                <OverlayTrigger overlay={<Tooltip id="button-tooltip">Editar</Tooltip>}>
                                                    <span className="d-inline-block">
                                                        <Link className="btn btn-warning btn-sm mx-1 mt-1 p-1" replace
                                                            to={`${match.url}/${a.id}/editarUsuario`}>

                                                            <i className="fas fa-user-edit" style={{ fontSize: 18 }} />

                                                        </Link>
                                                    </span>
                                                </OverlayTrigger>
                                                {
                                                    a.is_active === true?
                                                        <OverlayTrigger overlay={<Tooltip id="button-tooltip">Eliminar</Tooltip>}>
                                                        <span className="d-inline-block">
                                                            <Button className="btn btn-danger btn-sm mt-1 p-1" onClick={() => this.deleteUsuario(a.id)}><i className="fas fa-user-minus" style={{ fontSize: 18 }} /></Button>
                                                        </span>
                                                    </OverlayTrigger>
                                                    :
                                                    <OverlayTrigger overlay={<Tooltip id="button-tooltip">Activar</Tooltip>}>
                                                    <span className="d-inline-block">
                                                        <Button className="btn btn-succes btn-sm mt-1 p-1" onClick={() => this.activarUsuario(a.id)}><i className="fas fa-arrow-alt-circle-up" style={{ fontSize: 18 }} /></Button>
                                                    </span>
                                                </OverlayTrigger>
                                                }

                                                
                                            </div>
                                        ]
                                    }>

                                        ACCIONES
                            </VTable.THeader>

                                </VTable.Table>
                                <VTable.Pagination />
                            </VTable>
                        </Container>
                }
            </div>
        )
    }

    cerrarModal = ({ match: { params: { id } } }) => {
            const { props: { match, history} } = this
            history.replace(`${match.url}`)
    }

    modal = ({ match: { params: { id } } }) => {
        const { props: { usuarios, match, history, createUsuario, updateUsuario, roles, rolesUsuarios} } = this
        const [visible, setVisible] = useState(true)
        const handleSubmit = id ?

            (obj) =>
                updateUsuario(obj).then(
                    () => {
                        const error = this.props.error
                        if (error) {
                            new Noty({
                                type: "error",
                                text: error.response.data.Message,
                            }).show()
                        } else {
                            history.replace(`${match.url}`)
                            new Noty({
                                text: "Se ha actualizado el registro",
                                type: "success",
                                timeout: 2000
                            }).show()
                            this.props.fetchRolesUsuarios();
                            this.props.fetchUsuarios();
                        }
                    }
                )
            :
            (obj) =>
                createUsuario(obj).then(
                    () => {
                        const error = this.props.error
                        
                        console.log(this.props.error.data.Message)
                        if (error) {
                            console.log("ingresa")
                            new Noty({
                                type: "error",
                                text: error.response.data.Message + " ingresa",
                                timeout: 2400
                            }).show()
                            this.setState({ CONTINUE: true, BLOQUEAR: false, BLOQUEAR2: false })
                        } else {
                            new Noty({
                                text: "Se ha almacenado el registro, ya puede asignar roles.",
                                type: "success",
                                timeout: 2400
                            }).show()
                            this.setState({ CONTINUE: false, BLOQUEAR: true, BLOQUEAR2: true })
                            this.props.fetchListado();
                            this.props.fetchRolesUsuarios()
                            this.props.fetchUsuarios()
                        }
                    }
                )


        const usuario = id && usuarios.find(a => a.id.toString() === id.toString())

        return <Modal show={visible} dialogClassName="my-modal" size="xl" key="modal"
            onHide={() => setVisible(false)}
            onExited={() => history.replace(`${match.url}`)}
            backdrop={"static"}
            keyboard={false}>
            <Modal.Header>
                <Modal.Title>{
                    usuario ? `Editar registro: ${usuario.RUT}` : "Nuevo registro"
                }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    <>
                    <Container>
                    <Alert variant="warning" className="p-3"><i className="fas fa-exclamation-circle" style={{fontSize: 20}}></i> Estimado usuario: <b>verifique</b> si el usuario existe, luego haga click en continuar para asignar roles.</Alert>
                    </Container>
                    <Formulario
                        key={id}
                        onSubmit={handleSubmit}
                        request={id ? usuario : undefined}
                        roles={roles}
                        rolesUsuarios={rolesUsuarios}
                        seleccionRoles={this.handleClickRol}
                        fetchRolesUsuarios={this.props.fetchRolesUsuarios}
                        loadingUsuario={this.props.loadingUsuario}
                        cerrarModal={setVisible}
                    />
                    </>
                }
            </Modal.Body>
        </Modal>
    }
}


const mapStateToProps = store => ({
    loadingUsuario: store.usuario.Loader.isLoading,
    usuarios: store.usuario.Usuarios,
    roles: store.usuario.Roles,
    listado: store.usuario.Listado,
    rolesUsuarios: store.usuario.RolesUsuarios,
    error: store.usuario.ErrorCode,

});

const mapDispatchToProps = dispatch => ({
    fetchUsuarios: () => dispatch(fetchUsuariosAction()),
    fetchListado: () => dispatch(listadoUsuariosAction()),
    fetchRoles: () => dispatch(fetchRolesAction()),
    fetchRolesUsuarios: () => dispatch(RolesUsuariosAction()),
    createUsuario: (usuario) => dispatch(createUsuarioAction(usuario)),
    updateUsuario: (usuario) => dispatch(updateUsuarioAction(usuario)),
    deleteUsuario: (usuario) => dispatch(deleteUsuarioAction(usuario)),
    activarUsuario: (usuario) => dispatch(activarUsuarioAction(usuario)),
    createRol: (usuarioId, rol) => dispatch(createRolAction(usuarioId, rol)).then(() => dispatch(fetchUsuariosAction())),
    deleteRol: (usuarioId, rol) => dispatch(deleteRolAction(usuarioId, rol)).then(() => dispatch(fetchUsuariosAction())),

});

export default connect(mapStateToProps, mapDispatchToProps)(Tabla)