const initialState = {
    Collapsed: false,
    Miga: false,
    Menu: ''
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'TOGGLE_SIDEBAR':
            return {...state, Collapsed: !state.Collapsed};
        case 'ADD_MIGA':
            return {...state, Miga: action.payload}
        case 'MENU_SELECTED':
            return {...state, Menu: action.payload.menu};
        default:
            return state;
    }
}