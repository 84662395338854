import React, {Component} from 'react';
import {TableConsumer} from "./VTable";
class Pagination extends Component {
    static defaultProps = {
        maxPages: 5
    };
    render() {
        return (
            <TableConsumer> 
                {
                    ({rowsPerPage, handleRowsPerPageChange}) => {
                        return <div>
                            <strong>Filas por página</strong>
                            <table className="text-center">
                                <tbody>
                                <tr>
                                    <td className="pr-1">
                                <input checked={rowsPerPage === 5} onChange={handleRowsPerPageChange} type="radio" value={5}/>5
                                    </td>
                                    <td className="pr-1">
                                <input checked={rowsPerPage === 10} onChange={handleRowsPerPageChange}  type="radio" value={10}/>10
                                    </td>
                                    <td className="pr-1">
                                <input checked={rowsPerPage === 20} onChange={handleRowsPerPageChange}  type="radio" value={20}/>20
                                    </td>
                                </tr></tbody>
                            </table>
                        </div>;
                    }
                }
            </TableConsumer>
        );
    }
}

export default Pagination;