import React, {Component} from 'react'
import '../App.css'
import Auth from './Auth'
import {BrowserRouter, Route} from "react-router-dom"

export default class App extends Component {

    render = () => (
        <>
            <BrowserRouter>
                <div className="vh-100">
                    <Route path="/" component={Auth}/>
                </div>
            </BrowserRouter>
        </>
    )
}