import React, {Component} from 'react'
import { Image} from "react-bootstrap"


export default class Loading extends Component {
    render = () => {
        const {style, className} = this.props;
        return <div className={`d-flex justify-content-center align-content-center flex-wrap ${className}`} style={style}>
                <Image style={{ width: "60%" }} src={process.env.PUBLIC_URL + "/logo.gif"} />
        </div>
    };
}