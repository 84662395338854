import React, { Component } from "react"
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import Lista_F11 from "./Lista_F11";
import Lista_Paquetes from "./Lista_Paquetes";
import Lista_F11_Paquete from "./Lista_F11_Paquete";
import Lista_Conciliciaciones from "./Lista_Conciliciaciones";
import Lista_Sub_Conciliciaciones from "./Lista_Sub_Conciliciaciones";
import ConciliacionFalabella from "./ConciliacionFalabella"
import Lista_F11_Sub_Conciliciaciones from "./Lista_F11_Sub_Conciliaciones";

export class Contenedor extends Component {
    render = () => (
        <div className="h-100">
            <Container fluid className="px-5 py-1">
                <Switch>
                    <Route path="/falabella/lista_f11" component={Lista_F11} />
                    <Route path="/falabella/lista_paquetes" component={Lista_Paquetes} />
                    <Route path="/falabella/lista_f11_paquete/:id"  component={Lista_F11_Paquete}  />
                    <Route path="/falabella/lista_conciliaciones/:id"  component={Lista_Conciliciaciones}  />
                    <Route path="/falabella/lista_sub_conciliaciones/:id"  component={Lista_Sub_Conciliciaciones}  />
                    <Route path="/falabella/ConciliacionFalabella/:id/"  component={ConciliacionFalabella}  />
                    <Route path="/falabella/lista_f11_sub_conciliacion/:id_user/:id/:cod"  component={Lista_F11_Sub_Conciliciaciones }  />
                </Switch>
            </Container>
        </div>
    );
}

const mapStateToProps = store => ({
    // loading: store.contenedor.Loader.isLoading,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Contenedor);