export default class Loader {
    constructor(inProgress = 0) {
        this.inProgress = inProgress;
        this.isLoading = inProgress > 0;
    }

    load() {
        return new Loader(this.inProgress + 1);
    }

    complete() {
        if (this.inProgress === 0) {
            throw new Error("Nada por terminar")
        }
        else {
            return new Loader(this.inProgress - 1);
        }
    }
}

