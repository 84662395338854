let api = null;
//const ruta_api = process.env.REACT_APP_BASE_API;

switch (process.env.REACT_APP_STAGE) {
    case 'development':
        // Si te conectas a la API con el debug encendido utiliza esta opcion.
        api = "http://127.0.0.1:8000/";
        
        // Si te conectas a la API en el IIS, utiliza esta opcion
        //api = "http://localhost/"+ruta_api+"/";
        break;
    case 'test':
        api = "https://prolfaco-dev.falabella.com.co/";
        break;
    default:
        api = "https://prolfaco.falabella.com.co/";
}
export const api_uri = api;

//export const app_title = "Terminal - Patio Contenedores";

export const local_token = "token";
export const local_refresh = "refresh";
export const local = "local";
