import React, {Component} from 'react';
import {TableConsumer} from "./VTable";
class Pagination extends Component {
    static defaultProps = {
        maxPages: 5
    };
    render() {
        const {maxPages} = this.props;
        return (
            <TableConsumer>
                {
                    ({pages, page, handlePageChange}) => {
                        let arr = [];
                        const offset = Math.floor((maxPages - 1)/2);
                        let min = page - offset;
                        let max = page + offset;
                        if (pages > 0) {
                            if (min < 0) {
                                max -= min;
                                min = 0;
                            }
                            if (max >= pages) {
                                min += pages - max - 1;
                                max = pages - 1;
                                if (min < 0) {
                                    min = 0;
                                }
                            }
                            arr = [...Array(max - min + 1)].map((a,b) => {
                            const val = b + min;
                            return (
                                <li  key={val} className={`color-grey page-item${page === val ? ' active' : ''}`}>
                                    <button className="page-link"
                                            onClick={handlePageChange} value={val}>
                                        {val + 1}
                                    </button>
                                </li>
                            )});
                        } else {
                            min = 0;
                            max = 0;
                        }
                        return <ul className={`pagination ml-3 ${this.props.className}`}>
                            <li className="page-item">
                                <button onClick={handlePageChange} value={0} className="page-link" aria-label="Previous">
                                    <span><strong>&#8656;</strong></span>
                                </button>
                            </li>
                            <li className="page-item">
                                <button onClick={handlePageChange} value={page - 1} className="page-link" aria-label="Previous">
                                    <span>&larr;</span>
                                </button>
                            </li>
                            {
                                arr
                            }
                            <li className="page-item">
                                <button onClick={handlePageChange} value={page + 1} className="page-link" aria-label="Previous">
                                    <span>&rarr;</span>
                                </button>
                            </li>
                            <li className="page-item">
                                <button onClick={handlePageChange} value={999} className="page-link" aria-label="Previous">
                                    <span><strong>&#8658;</strong></span>
                                </button>
                            </li>
                        </ul>;
                    }
                }
            </TableConsumer>
        );
    }
}

export default Pagination;