import { local_token, local_refresh } from '../consts/consts'
import axios from 'axios'
import Noty from 'noty'
 
export const userLogin = auth => {
    return dispatch => {
        dispatch(loginUser({}));
        if (auth) {
            localStorage.removeItem(local_token);
            localStorage.removeItem(local_refresh);
            return axios.post(`api/v1/usuario/login/`, {
                username: auth.email,
                password: auth.password,
                token_captcha: auth.token_captcha
                
            }).then(d => {
                let data = d.data;
                new Noty({
                    type: "success",
                    text: "Bienvenido",
                    timeout: 1000,
                }).show();
                localStorage.setItem(local_token, data.token);
                localStorage.setItem(local_refresh, data.user.id);
                dispatch(authUser({ user: data.user, token: data.token, grupos: data.ROLES}));
            }).catch(error => {
                dispatch(authError({ status: error.response }));
                localStorage.removeItem(local_token);
                localStorage.removeItem(local_refresh);

            });
        }
        if (localStorage[local_token]) {
            const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
              
              let data = {
                user : localStorage[local_refresh]
              }
            return axios.post(`api/v1/usuario/refresh/`, data, config).then(
                d => {
                    let data = d.data;
                    dispatch(authUser({ user: data.user, token: localStorage[local_token], grupos: data.ROLES }));
                    
                }
            ).catch(error => {
                console.log(error);
                dispatch(authError({ status: error.response }));
                localStorage.removeItem(local_token);
                localStorage.removeItem(local_refresh);
            });
        }
    }
};

export const userLogout = () => dispatch => {
    const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
    let config = {
        headers: {
            Authorization: AuthStr
        }
    
    }
    let data = {
        user : localStorage[local_refresh]
      }
    axios.post(`api/v1/usuario/logout/`, data, config).then(
        
    ).catch(error => {
        console.log(error);
        dispatch(authError({ status: error.response }));
        localStorage.removeItem(local_token);
        localStorage.removeItem(local_refresh);
    });
    dispatch(logoutUser());

    localStorage.removeItem(local_token);
};




const loginUser = obj => ({
    type: 'LOGIN_USER',
    payload: obj
});

const authUser = obj => ({
    type: 'AUTH_USER',
    payload: obj
});

const logoutUser = () => ({
    type: 'LOGOUT_USER',
    payload: {}
});

const authError = obj => ({
    type: 'AUTH_ERROR',
    payload: obj
});