import { local_token } from '../../../consts/consts'
import React, { Component } from 'react'
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import VTable from '../../table/VTable'
import axios from 'axios'
import Axios from 'axios'
import moment from "moment"
import { Breadcrumb, Modal, Row, Container, Col, Button, Form, Alert, Tooltip, OverlayTrigger, Table } from "react-bootstrap"
import Noty from "noty"
import Workbook from 'react-excel-workbook'


class Lista_Paquetes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalsku: false,
            f11_srx: [],
            listado: [],
            cargando: false,
            bloquear: false,
            email: "",
            CODIGO: "",
            modalLiberar: false,
            listadosku: [{ID:"", F11: "", SKU: "", UPC: "", DESCRIPCION: "", CANTIDAD: "", COSTO_REPOSICION: "", COSTO_TOTAL: "" }],
            newlistadosku: [],
        }
    }

    handleChange = ({ currentTarget: { value, name, type } }) => {
        /*switch (type) {
            case "text": {
                value = value
            }
        }*/
        this.setState({ [name]: value });
        this.getListadoF12();
    };

    componentDidMount() {
        this.getListadoF12();
    };

    getListadoF12 = () => {
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        const { match: { params } } = this.props;
        const url = "/api/v1/conciliaciones/verPaquete/" + params.id + "/";
        axios.get(url,config)
            .then((response) => {
                this.setState({ CODIGO:response.data.CODIGO,listado: response.data.F11 })
            })
            .catch((error) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 500,
                    modal: true,
                    text: 'No se lograron cargar los F12'
                }).show();
            })
    };

    handleModalSku = f11 => () => {
        const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
              }
        const urls2 = "/api/v1/conciliaciones/f11/" + f11 + "/";
        var f_11 = []
        axios.get(urls2,config)
            .then((response) => {
                f_11.push(response.data);
                this.setState({f11_srx: response.data })
            })
            .catch((error) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 500,
                    modal: true,
                    text: 'No se encontró el f11'
                }).show();
            });
        this.setState({ deshabilitar: false });
        var listadosku = []
        const urlbase = 'api/v1/conciliaciones/sku/'
        const url = urlbase + f11 + '/'
        Axios.get(url, config)
            .then((response) => {
                response.data.forEach((elem) => {
                    var registro = {ID:elem.id, F11: elem.F11, SKU: elem.SKU, UPC: elem.UPC, DESCRIPCION: elem.DESCRIPCION, CANTIDAD: elem.CANTIDAD, COSTO_REPOSICION: elem.COSTO_REPOSICION, COSTO_TOTAL: elem.COSTO_TOTAL }
                    listadosku.push(registro)

                })
                this.setState({ listadosku: listadosku })
            })
            .catch((error) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 500,
                    modal: true,
                    text: 'Ha ocurrido un error'
                }).show();
            })
        const valor = this.state.modalsku
        this.setState({ modalsku: !valor });
    }

    excel = () => {
        const data = this.state.listado
        return (
            <Workbook filename="F11_CONCILIACIONES.xlsx" element={
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Descargar Excel</Tooltip>}>
                    <span className="d-inline-block">
                        <Button style={{ pointerEvents: 'not-allowed' }} variant="success"><i className="fas fa-download"></i></Button>&nbsp;
                    </span>
                </OverlayTrigger>}>

                <Workbook.Sheet data={data} name="listado">
                    <Workbook.Column label="F11" value="F11" />
                    <Workbook.Column label="F12" value="F12" />
                    <Workbook.Column label="SUCURSAL" value="SUCURSAL" />
                    <Workbook.Column label="TRANSPORTADORA" value="TRANSPORTADORA" />
                    <Workbook.Column label="LOCALABT" value="LOCALABT" />
                    <Workbook.Column label="TIPO" value="TIPO" />
                    <Workbook.Column label="OBSERVACION" value="OBSERVACION" />
                    <Workbook.Column label="ESTADO" value="ESTADO" />
                    <Workbook.Column label="FECHA DESPACHO" value="FECHA" />
                    <Workbook.Column label="TIPIFICACION" value="TIPIFICACION" />
                </Workbook.Sheet>
            </Workbook>
        )
    }

    render() {
        const { state: {  f11_srx, CODIGO }, } = this
        /*const options = {
            orientation: 'landscape'
        };*/
        return (
            <>
                <div>
                    <Breadcrumb>
                        <Breadcrumb.Item active><Link to="/">Inicio</Link></Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            Lista Paquetes
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Lista F11s</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Container className="p-2">
                    <Container style={{ display: "content" }}>
                        <h4><i className="fas fa-database"></i> Listado de F11s del paquete: {CODIGO}</h4>
                        <br />
                    </Container>
                    <br />
                    <div>
                        {this.state.listado.length > 0 ?
                            <Alert variant="success">{"Se han encontrado: " + this.state.listado.length + " registros"}</Alert>
                            :
                            undefined
                        }
                        <VTable data={this.state.listado} className="position-relative" orderBy="FECHA_REPARTO" rowsPerPage={20} orderDir="desc" sticky={false}>
                            {this.state.listado.length > 0 ?
                                <nav className="form-inline justify-content-end">
                                    <VTable.Filter className="justify-content-end form-control mb-2"
                                        placeHolder="Buscar..." />
                                </nav>
                                :
                                undefined
                            }
                            <VTable.Table className="table table-bordered table-hover table-sm text-center">
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.F11)}>F11</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.F12)}>F12</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.TRANSPORTADORA)}>TRANSPORTADORA</VTable.THeader>
                                {/* <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.TIPO)}>CIUDAD ENTREGA</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.TIPIFICACION)}>REGION ENTREGA</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.COSTO)}>COSTO</VTable.THeader> */}
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.user)}>USUARIO</VTable.THeader>                                
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.OBSERVACION)}>OBSERVACIONES</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "2%" }} index="FECHA" formatter={(a) => (moment(a).format("DD-MM-YYYY HH:mm"))}>FECHA DESPACHO</VTable.THeader>
                                {/*<VTable.THeader className="text-center" style={{ width: "2%" }} index="FECHA_REPARTO" formatter={(a) => (moment(a).format("DD-MM-YYYY HH:mm"))}>F. REPARTO</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "2%" }} index="ENTREGA_ESTIMADA" formatter={(a) => (moment(a).format("DD-MM-YYYY HH:mm"))}>F. ENTREGA ESTIMADA</VTable.THeader> */}
                                {/* <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.TRANSPORTE)}>Transporte</VTable.THeader> */}
                                <VTable.THeader className="text-center" style={{ width: "1%", }} formatter={
                                    a => [
                                        <div className="text-center" key="1">

                                            <Button title="Detalle" variant="primary" className="btn-sm mt-1 p-1 mb-2 mr-1" disabled={this.state.enviar || this.state.bajada_documento} icon="fa fa-users" onClick={this.handleModalSku(a.F11)} ><i className="far fa-eye" style={{ fontSize: 18 }} /></Button>
                                            {(() => {
                                                if (a.SOPORTE != null) {
                                                    if (a.SOPORTE_VALIDO === false) {
                                                        return (
                                                            <a title="Ver Soporte" target="_blank" rel="noopener noreferrer" href={a.SOPORTE} className="btn-danger btn-sm mt-1 p-1 mb-2 mr-1" icon="fa fa-users"><i className="fas fa-file-export" style={{ fontSize: 18 }} /></a>
                                                        )
                                                    }
                                                    if (a.SOPORTE_VALIDO === true) {
                                                        return (
                                                            <a title="Ver Soporte" target="_blank" rel="noopener noreferrer" href={a.SOPORTE} className="btn-success btn-sm mt-1 p-1 mb-2 mr-1" icon="fa fa-users"><i className="fas fa-file-export" style={{ fontSize: 18 }} /></a>
                                                        )
                                                    }
                                                }
                                            })()}
                                        </div>
                                    ]
                                }>
                                    ACCIONES
                                </VTable.THeader>
                            </VTable.Table>
                            <nav className="d-flex justify-content-end">
                                <VTable.Pagination className="" />
                            </nav>
                        </VTable>
                    </div>
                </Container>
                {
                    this.state.f11_srx &&
                        <Modal show={this.state.modalsku} onHide={() => this.setState({ modalsku: false })} dialogClassName="my-modal" size="xl" overflow="auto">
                            <Modal.Header closeButton>
                                <Modal.Title classname="mx-4">Detalle F11</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <Row>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>F11</b></h5>
                                        <h7>{this.state.f11_srx.F11}</h7>
                                        <hr></hr>
                                    </Col>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>F12</b></h5>
                                        <h7>{this.state.f11_srx.F12}</h7>
                                        <hr></hr>
                                    </Col>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>SUCURSAL</b></h5>
                                        <h7>{this.state.f11_srx.SUCURSAL}</h7>
                                        <hr></hr>
                                    </Col>
                                    
                                </Row>
                                <Row>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>TRANSPORTADORA</b></h5>
                                        <h7>{this.state.f11_srx.TRANSPORTADORA}</h7>
                                        <hr></hr>
                                    </Col>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>LOCALABT</b></h5>
                                        <h7>{this.state.f11_srx.LOCALABT}</h7>
                                        <hr></hr>
                                    </Col>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>TIPO</b></h5>
                                        <h7>{this.state.f11_srx.TIPO}</h7>
                                        <hr></hr>
                                    </Col>
                                    
                                </Row>
                                <Row>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>OBSERVACION</b></h5>
                                        <h7>{this.state.f11_srx.OBSERVACION}</h7>
                                        <hr></hr>
                                    </Col>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>ESTADO</b></h5>
                                        <h7>{this.state.f11_srx.ESTADO}</h7>
                                        <hr></hr>
                                    </Col>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>FECHA</b></h5>
                                        <h7>{this.state.f11_srx.FECHA}</h7>
                                        <hr></hr>
                                    </Col>
                                    
                                </Row>
                                <Row>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>PAQUETE</b></h5>                                        
                                        { 
                                        this.state.f11_srx.PAQUETE ===false?
                                        <h7>SIN ENVIAR</h7>
                                        :
                                        <h7>ENVIADO</h7>
                                        }
                                        <hr></hr>
                                    </Col>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>USUARIO</b></h5>
                                        <h7>{this.state.f11_srx.user}</h7>
                                        <hr></hr>
                                    </Col>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>TIPIFICACION</b></h5>
                                        <h7>{this.state.f11_srx.TIPIFICACION}</h7>
                                        <hr></hr>
                                    </Col>                                    
                                </Row>
                                <Row>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>SOPORTE</b></h5>                                        
                                        { 
                                        this.state.f11_srx.SOPORTE == null ?
                                        <h7>Sin soporte</h7>
                                        :
                                        <h7><a href={this.state.f11_srx.SOPORTE} >Ver soporte</a></h7>
                                        }
                                        <hr></hr>
                                    </Col>
                                    <Col xs={4} className="justify-content-end">
                                        <h5><b>SOPORTE VALIDO</b></h5>
                                        { 
                                        this.state.f11_srx.SOPORTE_VALIDO ===false?
                                        <h7>SOPORTE NO VALIDO</h7>
                                        :
                                        <h7>SOPORTE VALIDO</h7>
                                        }
                                        <hr></hr>
                                    </Col>                                    
                                </Row>
                                <br></br>

                                <Table responsive >
                                    <thead>
                                        <tr>
                                            <th className="text-center" width="3%">#</th>
                                            <th className="text-center" width="20%">SKU</th>
                                            <th className="text-center" width="20%">UPC</th>
                                            <th className="text-center" width="20%">Descripción</th>
                                            <th className="text-center" width="3%">Cantidad</th>
                                            <th className="text-center" width="20%">Costo reposicion</th>
                                            <th className="text-center" width="30%">Costo total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.listadosku.map((elem, idx) => (
                                            <tr>
                                                <td className="text-center">{idx + 1}</td>
                                                <td className="text-center">{elem.SKU}</td>
                                                <td className="text-center">{elem.UPC}</td>
                                                <td className="text-center">{elem.DESCRIPCION}</td>
                                                <td className="text-center">{elem.CANTIDAD}</td>
                                                <td className="text-center">{elem.COSTO_REPOSICION}</td>
                                                <td className="text-center">{elem.COSTO_TOTAL}</td>

                                            </tr>))}
                                    </tbody>

                                </Table>

                            </Modal.Body>
                            <Modal.Footer>

                            </Modal.Footer>
                        </Modal>
                    }
            </>
        )
    }
}

const mapStateToProps = store => ({
    user: store.auth.User,

})

export default connect(mapStateToProps)(Lista_Paquetes)