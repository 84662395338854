import React, {Component} from 'react';
import {TableConsumer} from "./VTable";

class THeader extends Component {
    defaultSortFunction = (a, b, dir) => {
        if (isNaN(a) || isNaN(b)) {
            return a ? (b ? (b.localeCompare(a) * (dir === 'asc' ? -1 : 1)) : -1) : 1;
        } else {
            return a ? (b ? (b - a) * (dir === 'asc' ? -1 : 1) : -1) : 1;
        }
    };

    formateaTitulo = str => str && str.split("(")[0];

    static defaultProps = {
        index: null,
        children: "",
        className: "",
        type: "text",
        formatter: (value, index) => {
            return typeof value == "string" ? value.toUpperCase() : value
        }
        ,
        sortFunction: null
    };

    constructor(props) {
        super(props);
        this.state = {
            sortFunction: props.sortFunction || this.defaultSortFunction
        }
    }

    render() {
        const {props: {children, className, style, index}, state: {sortFunction}} = this;
        return (
            <TableConsumer>
                {
                    ({orderBy, orderDir, handleOrderChange}) => (
                        <th 
                            scope="col"
                            className={`clearfix ${className} pb-0 pt-1 px-3 align-middle text-center`}
                            style={{ ...style }} 
                            onClick={e => index && handleOrderChange(index, sortFunction, e)} >
                                <span>
                                    {
                                        <span 
                                            title={children}  
                                            style={{fontSize:'smaller'}}
                                            className="my-0">
                                                {this.formateaTitulo(children)}
                                        </span>
                                    }
                                    {
                                        index && <span className="ml-3 float-right">
                                                    {
                                                        orderBy === index ?
                                                            orderDir === 'asc' ? <>&#9650;</> : <>&#9660;</>
                                                            :
                                                            <>&#8765;</>
                                                    }
                                                </span>
                                    }
                                </span>
                        </th>
                    )
                }
            </TableConsumer>
        );
    }
}

export default THeader;