import React, { Component } from "react";
import "./login.css";
import logo from "./barra/logo-prof1.png";
//import fondo from "./barra/atlasdani.png";
//import { withRouter } from "react-router-dom"; // new import
import { connect } from "react-redux"; // new import
//import PropTypes from "prop-types"; // new import
import Noty from "noty"
//import { Link } from "react-router-dom";
import PasswordInput from "./PasswordInput"
import { Button, Modal,  Form,  Col } from "react-bootstrap";
import { userLogin } from "../actions/authActions"
//import { signupNewUser } from "./SignupActions"; // new import
import ReCAPTCHA from "react-google-recaptcha";

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            recap: false,
            validarRecap:"",
            captcha:""
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.createAuth = this.createAuth.bind(this)
        this.setDominio = this.setDominio.bind(this)
    }
    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    // update function to call the action
    onSignupClick = () => {
        const userData = {
            email: this.state.email,
            password: this.state.password
        };
        this.props.userLogin(userData); // <-- signup new user request
    };

    handleFormSubmit(e) {
        e.preventDefault()
        if(this.state.recap){    
            this.setState({validarRecap:""});        
            let auth = {
                //email: this.state.email + this.state.dominio,
                email: this.state.email,
                password: this.state.password,
                token_captcha:this.state.captcha
            }
            this.props.loginUser(auth).then(() => {
                this.props.redireccionar();
            })
        }else{
            this.setState({validarRecap:"Validar Recaptcha"});
        }
        
    }

    createAuth(e) {
        const name = e.target.name
        const value = e.target.value

        this.setState(
            {
                [name]: value
            }
        )
    }

    
    setDominio(e) {
        const value = e.target.value
        if (value === "") {
            this.setState({ cuenta: "Correo electrónico o Rut", dominio: value })
        }
        if (value === "@falabella.com.co") {
            this.setState({ cuenta: "Cuenta NT", dominio: value })
        }
        if (value === "@falabella.cl") {
            this.setState({ cuenta: "Cuenta NT", dominio: value })
        }
        if (value === "@homecenter.com.co") {
            this.setState({ cuenta: "Cuenta NT", dominio: value })
        }
        if (value === "@falabella.com.pe") {
            this.setState({ cuenta: "Cuenta NT", dominio: value })
        }
        if (value === "@sodimac.com.pe") {
            this.setState({ cuenta: "Cuenta NT", dominio: value })
        }
        if (value === "@tottus.com.pe") {
            this.setState({ cuenta: "Cuenta NT", dominio: value })
        }
        if (value === "@sagafalabella.com.pe") {
            this.setState({ cuenta: "Cuenta NT", dominio: value })
        }
    }

    handleRecovery = (e) => {
        const { recoveryPass } = this.props
        e.preventDefault()
        this.setState({
            cargandoPass: true
        })
        let request = {
            CORREO: this.state.email + this.state.dominio,
        }
        recoveryPass(request)
            .then(() => {
                const error = this.props.error
                if (error) {
                    this.setState({
                        cargandoPass: false,
                        msgReco: error.response.data.Message,
                    })
                    new Noty({
                        type: "error",
                        text: error.response.data.Message,
                    }).show()
                }
                this.setState({
                    cargandoPass: false,
                    msgReco: null,
                    recovery: false,
                })
                // this.handleClear()
            })
    }

    modalRecovery = () => {
        let cargandoPass = this.state.cargandoPass;

        return (
            <>
                <Modal show={this.state.recovery} onHide={() => this.setState({ recovery: false, msgReco: false })}>
                    <Modal.Header closeButton>
                        <strong>Recuperar contraseña</strong>
                    </Modal.Header>
                    <Modal.Body>
                        <label>Ingrese su cuenta NT o correo electrónico (según corresponda) y se le enviará un correo con una nueva contraseña. Deberá cambiarla posteriormente.</label>
                        <form method="POST" onSubmit={this.handleRecovery} autoComplete="off">
                            {
                                this.state.msgReco &&
                                <div className="alert alert-danger">
                                    {this.state.msgReco}
                                </div>
                            }
                            {/* <div className="input-group">
                                <input type="nt" onChange={(e) => this.setState({ email: e.currentTarget.value })}
                                    className="form-control" placeholder="Cuenta NT" required/>
                                <div className="input-group-append">
                                    <span className="input-group-text"
                                        id="basic-addon2">@falabella.cl</span>
                                </div>
                            </div> */}
                            <div className="form-group">
                                <label className="mb-0" htmlFor="email">{this.state.cuenta}</label>
                                <div className="input-group">
                                    <input type="nt" className="form-control"
                                        required autoFocus
                                        placeholder={this.state.dominio !== "" ? undefined : "Rut sin digito verificador"}
                                        onChange={(e) => this.setState({ email: e.currentTarget.value })}
                                    />
                                    <div className="input-group-append">
                                        <Form.Group controlId="exampleForm.ControlSelect1" style={this.state.dominio !== "" ? undefined : { maxWidth: "25px" }}>

                                            <Form.Control className="input-group-text p-0" as="select" onChange={(e) => this.setDominio(e)}>
                                                <option value="@falabella.cl">@falabella.cl</option>
                                                <option value="@falabella.com.co">@falabella.com.co</option>
                                                <option value="@homecenter.com.co">@homecenter.com.co</option>
                                                <option value="">Externos</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div >
                                <Button type="submit" variant="primary" size="lg" block> {cargandoPass ? 'Enviando correo...' : 'Enviar'} </Button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </>
        )
    }

    render() {

        const onChange = () =>{
            this.setState({validarRecap:"", recap:true});
        }
        return (
            <>
                {this.modalRecovery()}
                <div className="row p-0 m-0">
                    <Col className="overflow-auto w-0 px-0" xs sm md lg={8}>
                        <div className="bg "></div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={4}>
                        <div>
                            <br></br>
                            <section className="h-100">
                                <div className="container h-100">
                                    <div className="row justify-content-md-center h-100">
                                        <div className="card-wrapper">
                                            {/* <h4 align="center">ATLAS</h4> */}
                                            <div className="card fat">
                                                <img className="logo img-responsive center-block" src={logo} alt="logo" />
                                                <div className="card-body">
                                                    <h5 className="card-title">Bienvenido</h5>
                                                    <br></br>
                                                    <form method="POST" className="my-login-validation" noValidate=""
                                                        onSubmit={this.handleFormSubmit}>
                                                        <div className="form-group">
                                                            <label className="mb-0" htmlFor="email">Usuario</label>
                                                            <div className="input-group">
                                                                <input id="email" type="text" className="form-control" name="email"
                                                                    tabIndex="1"
                                                                    required autoFocus
                                                                    placeholder={this.state.dominio !== "" ? undefined : "Rut sin digito verificador"}
                                                                    onChange={this.createAuth}
                                                                />
                                                                
                                                            </div>
                                                            <div className="invalid-feedback">
                                                                Usuario es obligatorio
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="mb-0" htmlFor="password">Contraseña
                                                            </label>
                                                            <PasswordInput onChange={this.createAuth} id="password" type="password" name="password" required />
                                                            <br></br>
                                                            <div className="recaptcha">
                                                            <ReCAPTCHA
                                                                sitekey="6LeAqfEhAAAAAOSor2tBX0Q6AQ7RbHHq27OWFyEb"
                                                                id="recap"
                                                                name="recap"
                                                                onChange={(t)=>{this.setState({captcha:t,validarRecap:"", recap:true}) } }
                                                                //onChange={this.createAuthRecap}
                                                            />
                                                            <br></br>
                                                            {
                                                                this.state.validarRecap !== ""?
                                                                    <p>{this.state.validarRecap}</p>
                                                                :
                                                                undefined
                                                            }
                                                            </div>
                                                            <div className="invalid-feedback">
                                                                Contraseña es obligatoria
                                                            </div>
                                                        </div>
                                                        <br></br>
                                                        <div className="form-group m-0">
                                                            <button type="submit" className="btn btn-info btn-block"
                                                                tabIndex="3">
                                                                Iniciar Sesión
                                                            </button>
                                                        </div>
                                                        <br></br>
                                                        <br></br>
                                                        <div align="right" className="mt-2">
                                                            
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </Col>
                    {/* <Col md="4">
                    <h1>Bienvenido </h1>
                    <h1>APP-RELACIONAMIENTO</h1>
                    <Form>
                        <Form.Group controlId="usernameId">
                            <Form.Label>User name</Form.Label>
                            <Form.Control
                                isInvalid={this.props.authError}
                                type="text"
                                name="username"
                                placeholder="Enter user name"
                                value={this.state.username}
                                onChange={this.onChange}
                            />
                            <FormControl.Feedback type="invalid">
                            </FormControl.Feedback>
                        </Form.Group>
                        <Form.Group controlId="passwordId">
                            <Form.Label>Your password</Form.Label>
                            <Form.Control
                                isInvalid={this.props.authError}
                                type="password"
                                name="password"
                                placeholder="Enter password"
                                value={this.password}
                                onChange={this.onChange}
                            />
                            <Form.Control.Feedback type="invalid">
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                    <Button color="primary" onClick={this.onSignupClick}>
                        Sign up
                    </Button>
                </Col> */}
                </div>
            </>
        );
    }
}

// connect action and reducer
// replace 
// export default Signup;
// with code below:

// Signup.propTypes = {
//     userLogin: PropTypes.func.isRequired,
//     createUser: PropTypes.object.isRequired
// };

// const mapStateToProps = state => ({
//     createUser: state.createUser
// });

// export default connect(mapStateToProps, {
//     userLogin
// })(withRouter(Signup));

const mapStateToProps = store => ({
    auth: !!store.auth.User,
    loading: store.auth.Loader.isLoading,
    error: store.auth.ErrorCode,
})

const mapDispatchToProps = dispatch => ({
    loginUser: (obj) => dispatch(userLogin(obj)),
    //recoveryPass: (obj) => dispatch(recoveryPassAction(obj)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Signup)