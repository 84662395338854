import React, { Component } from 'react'
import { connect } from "react-redux"
import { Link, } from "react-router-dom"
import VTable from '../../table/VTable'
import axios from 'axios'
import { Breadcrumb,  Container, Button,  Tooltip, OverlayTrigger } from "react-bootstrap"
import Noty from "noty"
import Workbook from 'react-excel-workbook'


class Lista_Paquetes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            files: null,
            modalSoporte: false,
            f11_srx: [],
            listado: [],
            cargando: false,
            bloquear: false,
            email: "",
            CODIGO: "",
            modalLiberar: false,
            listadosku: [{ F11: "", SKU: "", UPC: "", DESCRIPCION: "", CANTIDAD: "", COSTO_REPOSICION: "", COSTO_TOTAL: "" }],
            newlistadosku: [],
            listadoEstadosConci: [],
            listadoMotivos: [],
            ESTADO: '',
            COSTO: '',
            F11: '',
            MOTIVO_DECLINADO: '',
            id_con: '',
            ID_CONCILIACION: '',
            ENVIO: true,
        }
    }
    componentDidMount() {
        this.getListadoF12();
    };

    getListadoF12 = () => {
        const { match: { params } } = this.props;
        var url = "api/v1/f11/ver_sub_conciliacion/" + params.id + "/" + params.cod + "/";
        var ID_CON = ''
        var ENVIO = ''
        axios.post(url)
            .then((response) => {
                this.setState({ listado: response.data, ID_CONCILIACION: ID_CON, ENVIO: ENVIO });
            })
            .catch((error) => {
                new Noty({
                    type: 'error',
                    layout: 'topCenter',
                    theme: 'bootstrap-v4',
                    timeout: 500,
                    modal: true,
                    text: 'No se logró cargar los f11'
                }).show();
            })
    };

    excel = () => {
        const data = this.state.listado
        return (
            <Workbook filename="F11_APROBADOS_CONCILIACIONES.xlsx" element={
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Descargar Excel</Tooltip>}>
                    <span className="d-inline-block">
                        <Button style={{ pointerEvents: 'not-allowed' }} variant="success"><i className="fas fa-download"></i></Button>&nbsp;
                    </span>
                </OverlayTrigger>}>

                <Workbook.Sheet data={data} name="listado">
                    <Workbook.Column label="F11" value={(col) => col.F11.F11} />
                    <Workbook.Column label="F12" value={(col) => col.F11.F12} />
                    <Workbook.Column label="SUCURSAL" value={(col) => col.F11.SUCURSAL} />
                    <Workbook.Column label="TRANSPORTADORA" value={(col) => col.F11.TRANSPORTADORA} />
                    <Workbook.Column label="LOCALABT" value={(col) => col.F11.LOCALABT} />
                    <Workbook.Column label="TIPO" value={(col) => col.F11.TIPO} />
                    <Workbook.Column label="OBSERVACION" value={(col) => col.F11.OBSERVACION} />
                    <Workbook.Column label="FECHA DESPACHO" value={(col) => col.F11.FECHA} />
                    <Workbook.Column label="TIPIFICACION" value={(col) => col.F11.TIPIFICACION} />
                    <Workbook.Column label="COSTO" value={(col) => col.F11.COSTO} />
                    <Workbook.Column label="SKU" value="SKU" />
                    <Workbook.Column label="UPC" value="UPC" />
                    <Workbook.Column label="DESCRIPCION" value="DESCRIPCION" />
                    <Workbook.Column label="CANTIDAD" value="CANTIDAD" />
                    <Workbook.Column label="COSTO_REPOSICION" value="COSTO_REPOSICION" />
                    <Workbook.Column label="COSTO_TOTAL" value="COSTO_TOTAL" />
                    <Workbook.Column label="VALOR_CONCILIADO" value={(col) => col.F11.VALOR_CONCILIADO} />
                </Workbook.Sheet>
            </Workbook>
        )
    }

    render() {
        const {  state: { CODIGO } } = this
        /*const options = {
            orientation: 'landscape'
        };*/
        return (
            <>
                <div>
                    <Breadcrumb>
                        <Breadcrumb.Item active><Link to="/">Inicio</Link></Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            Lista Subconciliaciones
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Lista F11s</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Container className="p-2">
                    <Container style={{ display: "content" }}>
                        <h4><i className="fas fa-database"></i> Listado de F11s de la Sub conciliación: {CODIGO}</h4>
                        <br />
                    </Container>

                    <br />
                    <div>



                        <VTable data={this.state.listado} className="position-relative" orderBy="FECHA_REPARTO" rowsPerPage={20} orderDir="desc" sticky={false}>
                            {this.state.listado.length === 0 ?
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Descargar Excel</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button style={{ pointerEvents: 'none' }} disabled variant="success"><i className="fas fa-download"></i></Button>&nbsp;
                                    </span>
                                </OverlayTrigger> :
                                this.excel()
                            }
                            {this.state.listado.length > 0 ?
                                <nav className="form-inline justify-content-end">
                                    <VTable.Filter className="justify-content-end form-control mb-2"
                                        placeHolder="Buscar..." />
                                </nav>
                                :
                                undefined
                            }

                            <VTable.Table className="table table-bordered table-hover table-sm text-center">
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.F11.F11)}>F11</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.SKU)}>SKU</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.UPC)}>UPC</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.DESCRIPCION)}>OBSERVACIONES</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.CANTIDAD)}>CANTIDAD</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.COSTO_TOTAL)}>COSTO TOTAL</VTable.THeader>
                                <VTable.THeader className="text-center" style={{ width: "1%" }} formatter={(a) => (a.F11.VALOR_CONCILIADO)}>VALOR CONCILIADO</VTable.THeader>
                            </VTable.Table>
                            <nav className="d-flex justify-content-end">
                                <VTable.Pagination className="" />
                            </nav>
                        </VTable>
                    </div>
                </Container>

            </>
        )
    }
}

const mapStateToProps = store => ({
    user: store.auth.User,
})


export default connect(mapStateToProps)(Lista_Paquetes)