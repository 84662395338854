import Loader from "../helpers/Loader";

const loader = new Loader();

const initialState = {
    User: null,
    Token: null,
    Refresh: null,
    Grupos: null,
    Loader: loader,
    ErrorCode: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'LOGIN_USER':
            return {...state, Loader: state.Loader.load()};
        case 'AUTH_USER':
            return {User: action.payload.user, Token: action.payload.token, Refresh: action.payload.refresh, Grupos: action.payload.grupos, Loader: state.Loader.complete()};
        case 'LOGOUT_USER':
            return {...state, User: null, Token: null, Grupos:null };
        case 'AUTH_ERROR':
            return {User: null, Token: null, Refresh: null, Grupos: null, ErrorCode: action.payload.status,  Loader: state.Loader.complete()};
        default:
            return state;
    }
}