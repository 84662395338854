import React from 'react'
import ReactDOM from 'react-dom'
import configureStore from './store/configureStore';
import { Provider } from 'react-redux'
import Noty from 'noty'
import axios from 'axios'
import App from './components/App'
import './app.scss'
import {api_uri} from "./consts/consts";


const store = configureStore;
Noty.overrideDefaults({
    theme: 'bootstrap-v4',
    layout   : 'topRight',
    timeout: 3000,
});

axios.defaults.baseURL = api_uri;

const rootElement = document.getElementById('root');
ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    rootElement
);