import React, {Component, createContext} from 'react'
import {Card} from "react-bootstrap";
//import Foto from "./Foto";
import Ficha from "../ficha/Ficha";
import Permisos from "./Permisos";
import {connect} from "react-redux";
import './sidebar.scss'
const SidebarContext = createContext();

export class Sidebar extends Component {
    static Permisos = Permisos;
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user,
            grupos: this.props.grupos,
        };
    }

    render() {
        const classes = [
            "main-sidebar",
            "px-0",
            this.props.collapsed && "w-0"
        ];
        const { children } = this.props;
        return (
            <SidebarContext.Provider value={this.state}>
                <div
                    className={classes.join(" ")}
                    style={{zIndex:2}}>
                    <Card className="h-100">
                        <Card.Header className="p-0 rounded shadow" style={{zIndex:1}}>
                            <Ficha user={this.state.user}/>
                        </Card.Header>
                        <Card.Body className="overflow-auto">
                            {children}
                        </Card.Body>
                    </Card>
                </div>
            </SidebarContext.Provider>
        )
    }
}

export const SidebarConsumer = SidebarContext.Consumer;


const mapStateToProps = store => ({
    collapsed: store.menu.Collapsed,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);