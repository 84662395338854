import React, {Component} from 'react';
import {Collapse, Nav} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {selectMenuAction} from "../../actions/menuActions";

class Opcion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: !!props.collapsed
        };
    }

    componentWillMount = () => {
        const uri = window.location.pathname;
        const uriPat = uri.split(process.env.REACT_APP_BASE_URL)[1];

        this.props.selectMenu(uri);
    }

    render() {
        const uri = window.location.pathname;
        const uripat = uri.split(process.env.REACT_APP_BASE_URL)[1];

        return (
            <div className="card mb-2 shadow">
                <div className="card-header text-falabella align-items-center d-flex"
                     onClick={()=>this.setState({collapsed: !this.state.collapsed})}>
                    <i className={`mr-1 fa-fw  fa-2x ${this.props.rol.icon}`}/>
                    <span className="flex-grow-1">{this.props.rol.nombre}</span>

                    <span className="fa-stack">
                        <i className={`active fa-stack-1x fa fa-minus ${this.state.collapsed ? 'collapsed' : ''}`}/>
                        <i className={`inactive fa-stack-1x fa fa-plus ${this.state.collapsed ? 'collapsed' : ''}`}/>
                    </span>
                </div>
                <Collapse in={!this.state.collapsed}>
                    <div>
                        <div className="card-body">
                            <div className="list-group">
                                <Nav defaultActiveKey="/home" className="flex-column">
                                {
                                    this.props.rol.opciones.map(
                                        opcion => {
                                            return(
                                                <NavLink key={opcion.slug}
                                                        className={opcion.uri === uri && opcion.uri === this.props.menuSelected? "option-selected pr-4":"text-gray pr-4"}
                                                        to={opcion.uri} 
                                                        onClick={() => this.props.selectMenu(opcion.uri)}
                                                        title={opcion.nombre}>
                                                    <i className={`mr-2 fa-fw fa-1x align-middle ${opcion.icon}`}/>
                                                    {opcion.nombre}
                                                </NavLink>
                                        )}
                                    )
                                }
                                </Nav>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
        );
    }
}
const mapStateToProps = store => ({
    menuSelected: store.menu.Menu
});

const mapDispatchToProps = dispatch => ({
    selectMenu: (menu) => dispatch(selectMenuAction(menu)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Opcion);
