import { local_token, local_refresh } from '../../consts/consts'
import axios from 'axios'
import React, {Component} from 'react';
import {Button, Container, Form, Nav, Navbar,NavDropdown,Modal,Col} from 'react-bootstrap'
import logo from './logo.png'
import {NavLink} from "react-router-dom";
import {userLogout} from "../../actions/authActions";
import {connect} from "react-redux";
import {toggleSidebarAction} from "../../actions/menuActions";
import Noty from 'noty';
import './barra.scss'
import PasswordInput from "./../PasswordInput"

const styles = {
    titulo:{
        padding: '0',
        margin: '0',
        fontFamily:'system-ui',
        fontVariant: 'all-petite-caps'
    }
}

export class Barra extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            showModalPass: false,
            password:'',
            password2:'',
            cambioerror:''
        }
    }

    handleLogout = () => {
        let noty = new Noty({
            text: '¿Desea cerrar sesión?',
            className:"text-center",
            buttons: [
                Noty.button('Si', 'btn btn-success mr-2', () => {
                    
                    this.props.userLogout();
                    noty.close();
                    window.close();
                }),
                Noty.button('No', 'btn btn-secondary', () => {
                    noty.close();
                }),
            ]
        });
        noty.show();
    };

    handleCambioPass = () => {
        this.setState({showModalPass: true})
    };

    handleChange = ({ currentTarget: { value, name, type } }) => {
        this.setState({ [name]: value });
    };



    handleCambiarPass = () => {
        
        if (this.state.password===this.state.password2){            
            this.setState({showModalPass: false,cambioerror:''});
            const AuthStr = 'Bearer '.concat(localStorage[local_token]); 
            let config = {
                headers: {
                    Authorization: AuthStr
                }
            
            }
            let data = {
                "password": this.state.password,
                "password2":this.state.password2
            }
            axios.post(`api/v1/usuario/usuario/${localStorage[local_refresh]}/set_pasword/`,data, config).then( 
                d=>{
                    let noty = new Noty({
                        text: d.data.message,
                        className:"text-center",
                    });
                    noty.show();
                }
            ).catch(error => {
                let noty = new Noty({
                    text: error.data.message,
                    className:"text-center",
                });
                noty.show();
            });

            
        }else{
            this.setState({cambioerror: 'Las contraseñas deben ser iguales'})
        }


        
    };


    render = () => {
        const isCollapse = this.props.collapsed;
        return(
            <Navbar className="navbar-dark bg-dark justify-content-between main-navbar barra pt-2" fixed="top">
                <Container fluid>
                    <Nav className="justify-content-first">
                        <Form inline className="order-last order-md-first">
                            <Button
                                variant="outline-dark"
                                className="border-gray"
                                style={{color: isCollapse ? "black" :'#cbd300', background: isCollapse ? '#cbd300':'black'}}
                                size="sm" 
                                onClick={() => this.props.toggleSidebar()}
                            >
                                &#9776;
                            </Button>
                        </Form>
                    </Nav>
                    <Nav>
                        <Navbar.Brand className="d-none d-md-inline-block">
                            <img
                                src={logo}
                                height="30"
                                className="d-inline-block align-top mx-4"
                                alt="Logística"
                            />
                        </Navbar.Brand>
                    </Nav>
                    <Nav className="flex-grow-1 justify-content-center">
                        <h6 style={styles.titulo} className="titulo pl-0">
                            <Navbar.Text>
                                <NavLink to="/">{process.env.REACT_APP_NOMBRE}</NavLink>
                            </Navbar.Text> 
                        </h6>
                    </Nav>
                    <Nav className="justify-content-end">
                    <span className="text-gray text-center navbar-text">
                        <b>OPCIONES</b>
                        </span>
                <NavDropdown id="basic-nav-dropdown" alignRight>
                    
                    <NavDropdown.Header>Opciones</NavDropdown.Header>

                    <NavDropdown.Item onClick={this.handleCambioPass}>
                        <span className="input-group-append ">            
                        <span className="fa fa-lock-open"/> 
                        Cambiar Contraseña
                        </span>
                    </NavDropdown.Item>
                    <NavDropdown.Divider/>
                    <NavDropdown.Item onClick={this.handleLogout}>
                    <span className="input-group-append ">            
                        <span className="fa fa-power-off"/> 
                        Cerrar sesión
                    </span>
                    </NavDropdown.Item>

                    </NavDropdown>
                    </Nav>
                </Container>
                <Modal show={this.state.showModalPass} onHide={() => this.setState({ showModalPass: false })} dialogClassName="my-modal" size="xs" overflow="auto">
                    <Modal.Header closeButton>
                        <Modal.Title classname="mx-4">Cambiar contraseña</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form.Row className="mt-3">
                            <Col xs={10}>
                                <PasswordInput id="password" onChange={this.handleChange} placeholder="Contraseña nueva" type="password" name="password" required />
                                <br></br>
                            </Col>       
                                           
                            <Col xs={10}>
                                <PasswordInput onChange={this.handleChange} id="password2" placeholder="Confirmar contraseña nueva" type="password" name="password2" required />
                                <br></br>
                            </Col>
                            {
                                this.state.cambioerror!='' ?
                                <Col xs={10}>
                                {this.state.cambioerror}
                                </Col>
                                :
                                undefined
                            }
                            <Col xs={4} className="justify-content-end">
                                <label>&nbsp;&nbsp;</label>
                                <br></br>
                                <Button class="text-center" onClick={this.handleCambiarPass} variant="success" >Confirmar</Button>
                            </Col>
                            
                        </Form.Row>
                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal>
            </Navbar>
            
            
        )}
}
const mapStateToProps = store => ({
    collapsed: store.menu.Collapsed,
});

const mapDispatchToProps = dispatch => ({
    toggleSidebar: () => dispatch(toggleSidebarAction()),
    userLogout: ()=>dispatch(userLogout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Barra);