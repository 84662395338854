import React, {Component} from 'react';
import {TableConsumer} from "./VTable";
import "./VTable.scss"

class Table extends Component {
    static defaultProps = {
        rowClassName: () => undefined,
    };

    rowClassName = (row) => {
        const {rowClassName} = this.props;
        if (rowClassName instanceof Function) {
            return rowClassName(row);
        }
        if (rowClassName instanceof String) {
            return rowClassName;
        }
    };

    rows = (pageData, fillBlanks, rowsPerPage, children) => {
        const {props: {patio}} = this;

        if(!pageData.length) {
            return <>
                <tr>
                    <td colSpan={children.length}>{patio ? "El CONTENEDOR no está en el Patio" : "No existen datos"}</td>
                </tr>
                {
                    fillBlanks && [...Array(rowsPerPage - 1)].map((a,b)=>
                        <tr key={b}>
                            <td colSpan={children.length}>&nbsp;</td>
                        </tr>
                    )
                }
            </>
        }
        return <>
            {
                pageData.map(
                    (a, n) =>
                        <tr key={n} className={this.rowClassName(a)}>
                            {
                                children.map(({props: {index, formatter, binded}}, count) =>{
                                        let val = index === null ? a :  a[index] === null ? "" : a[index];

                                        return <td key={count}>
                                                    {formatter(val, count)}
                                                </td>
                                    }
                                )
                            }
                        </tr>
                )
            }
            {
                fillBlanks && [...Array(rowsPerPage - pageData.length)].map(()=>
                    <tr>
                        <td colSpan={children.length}>&nbsp;</td>
                    </tr>
                )
            }
        </>

    };

    render() {
        const {className} = this.props;
        const children = React.Children.toArray(this.props.children);
        return (
            <TableConsumer>
                {
                    ({pageData, fillBlanks, rowsPerPage}) => (
                        <table className={`sticky-header ${className}`}>
                            <thead className="thead-light">
                                <tr>
                                    {
                                        children
                                    }
                                </tr>
                            </thead>
                            <tbody>
                            {
                                this.rows(pageData, fillBlanks, rowsPerPage, children)
                            }
                            </tbody>
                        </table>
                    )
                }
            </TableConsumer>
        );
    }
}

export default Table;