import React, {Component} from 'react';
import {TableConsumer} from "./VTable";

class Filter extends Component {
    render() {
        const {placeHolder, className} = this.props;
        return (
            <TableConsumer>
                {
                    ({filterText, handleFilterChange}) => (
                        <input placeholder={placeHolder} className={className} value={filterText} onChange={handleFilterChange}/>
                    )
                }
            </TableConsumer>
        );
    }
}

export default Filter;